import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//helpers
import { processAuthors, checkEmpty, getAuthorLink } from '../../../helpers'
import { isEmpty } from 'lodash'
//styles
import './metaitem.scss'
import { Link } from 'react-router-dom'

const MetaItem = ({ product }) => {
  const properties = {
    processLan() {
      if (!product.display_idioma) return null
      const lans = product.display_idioma
        .map((val, i) => {
          return val.nombre
        })
        .join(',')
      return lans.length >= 1 ? lans : 'N/A'
    },
    processSize() {
      if (!product) return null
      const size =
        !product.alto_cm & !product.ancho_cm
          ? '0 cm x 0 cm'
          : `${product.alto_cm} cm x ${product.ancho_cm} cm`
      return size
    },
    processEncuadernacion() {
      if (!product.display_encuadernacion) return null
      const bookBinding = product.display_encuadernacion
        .map((val, i) => {
          return `Formato ${val.formato} - ${val.descripcion}`
        })
        .join(',')
      return bookBinding
    },
  }

  const renderCine = () => (
    <>
      <Col lg={4} className="tableLeft">
        <p>Duración</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>{product.duracion || 0}</p>
      </Col>
      <Col lg={4} className="tableLeft">
        <p>Formáto Pantalla</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>{product.formato_pantalla || 'N/A'}</p>
      </Col>
      <Col lg={4} className="tableLeft">
        <p>Calidad</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>
          {product.calidad
            ? product.calidad.map((val, i) => val.descripcion).join(',')
            : 'N/A'}
        </p>
      </Col>
      <Col lg={4} className="tableLeft">
        <p>Géneros</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>
          <span className="genres">
            {product.generos
              ? product.generos.map((val, i) => val.nombre).join(',')
              : 'N/A'}
          </span>
        </p>
      </Col>
    </>
  )

  const renderLibros = () => (
    <>
      {product.alto_cm & product.ancho_cm ? (
        <>
          <Col lg={4} className="tableLeft">
            <p>tamaño</p>
          </Col>
          <Col lg={8} className="tableRight">
            <p>{properties.processSize()}</p>
          </Col>
        </>
      ) : null}
      {product.num_paginas ? (
        <>
          <Col lg={4} className="tableLeft">
            <p>número de páginas</p>
          </Col>
          <Col lg={8} className="tableRight">
            <p>{product.num_paginas}</p>
          </Col>
        </>
      ) : null}
      {product.num_edicion ? (
        <>
          <Col lg={4} className="tableLeft">
            <p>número de edición</p>
          </Col>
          <Col lg={8} className="tableRight">
            <p>{product.num_edicion || 0}</p>
          </Col>
          <Col lg={4} className="tableLeft">
            <p>encuadernación</p>
          </Col>
          <Col lg={8} className="tableRight">
            <p>{properties.processEncuadernacion()}</p>
          </Col>
        </>
      ) : null}
    </>
  )

  const productInfoRender = () => {
    if (product.type === 'cine') {
      return renderCine()
    } else if (product.type === 'libro') {
      return renderLibros()
    }
  }

  return (
    <Row>
      <Col lg={4} className="tableLeft">
        <p>EAN</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>
          <span className="ean-number">{product.ean}</span>
        </p>
      </Col>
      <Col lg={4} className="tableLeft">
        <p>Título</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>{product.titulo}</p>
      </Col>
      <Col lg={4} className="tableLeft">
        <p>Autores</p>
      </Col>
      <Col lg={8} className="tableRight">
        <p>
          <Link to={`/autor/${getAuthorLink(product)}/`}>
            <span className="autor">{processAuthors(product)}</span>
          </Link>
        </p>
      </Col>
      {!isEmpty(product) ? (
        product.idioma.length >= 1 ? (
          <>
            <Col lg={4} className="tableLeft">
              <p>Idioma</p>
            </Col>
            <Col lg={8} className="tableRight">
              <p>{properties.processLan()}</p>
            </Col>
          </>
        ) : null
      ) : null}
      {!isEmpty(product) ? (
        !product.editorial && product.type === 'libro' ? (
          <>
            <Col lg={4} className="tableLeft">
              <p>Editorial</p>
            </Col>
            <Col lg={8} className="tableRight">
              <p>
                {product.editorial ? (
                  <span className="editorial">
                    {product.editorial.nombre_fiscal}
                  </span>
                ) : (
                  'NA'
                )}
              </p>
            </Col>
          </>
        ) : null
      ) : null}
      {product?.fecha_public?.slice(0, 4) !== '0001' && (
        <>
          <Col lg={4} className="tableLeft">
            <p>Año publicación</p>
          </Col>
          <Col lg={8} className="tableRight">
            <p>
              <p>{product?.fecha_public?.slice(0, 4)}</p>
            </p>
          </Col>
        </>
      )}

      {productInfoRender()}
    </Row>
  )
}

export default MetaItem

import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import { userSelector } from '../slices/user'
import { useSelector } from 'react-redux'

// layouts
import DefaultLayout from '../layouts/defaultLayout'

// pages
import HomePage from '../pages/home'
import ListPage from '../pages/list'
import BookDetail from '../pages/detail/index'
import OrderDetail from '../pages/orders/detail'
import CoffeeShopPage from '../pages/cafeteria'
import GalleryPage from '../pages/gallery'
import EventsPage from '../pages/event'
import EventDetailPage from '../pages/event/detail'
import ProfilePage from '../pages/profile'
import Cart from '../pages/cart'
import OkPayment from '../pages/cart/ok_payment'
import ErrorPayment from '../pages/cart/error_payment'
import DynamicFilter from '../pages/dynamicFilter/'
import Nosotros from '../pages/Nosotros/Nosotros'
import Legals from '../pages/legals'
import Refund from '../pages/refund'
import Conditions from '../pages/conditions'
import SearchAdvanced from '../pages/searchAdvanced/'
import FavoritesPage from '../pages/favorites'
import Pedidos from '../pages/orders'
import { PostList } from '../pages/blog'
import Post from '../pages/blog/post'
import RecoverPage from '../pages/recover'
import FeaturedMattersPage from '../pages/matters/featuredMatters'
import Cafeteria from '../pages/cafeteria'
import NotFound from '../pages/404'

const DefaultRoute = ({ component: Component, ...rest }) => {
  const { isLogged } = useSelector(userSelector)
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.protected ? (
          !isLogged ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <DefaultLayout>
              {' '}
              <Component {...props} />{' '}
            </DefaultLayout>
          )
        ) : (
          <DefaultLayout>
            {' '}
            <Component {...props} />{' '}
          </DefaultLayout>
        )
      }
    />
  )
}

const MainRoute = () => {
  return (
    <Router>
      <Switch>
        <DefaultRoute exact path="/" component={HomePage} />
        {/*<DefaultRoute
                  exact
                  path="/mercaderia"
                  // exact={true}
                  component={ListPage}
                />*/}
        <DefaultRoute exact path="/lista/:nombre" component={ListPage} />
        {/* <DefaultRoute exact path="/materia/:src/:code" component={ListPage} /> */}
        <DefaultRoute exact path="/materia/:matterName" component={ListPage} />
        <DefaultRoute exact path="/autor/:slug" component={ListPage} />
        <DefaultRoute exact path="/editorial/:slug" component={ListPage} />
        <DefaultRoute
          exact
          path="/materias-destacadas"
          component={FeaturedMattersPage}
        />
        <DefaultRoute exact path="/cafeteria/" component={CoffeeShopPage} />
        <DefaultRoute exact path="/galeria" component={GalleryPage} />
        <DefaultRoute exact path="/eventos" component={EventsPage} />
        <DefaultRoute exact path="/eventos/:id" component={EventDetailPage} />
        <DefaultRoute exact path="/carrito" component={Cart} />
        <DefaultRoute exact path="/pago-ok" component={OkPayment} />
        <DefaultRoute exact path="/pago-error" component={ErrorPayment} />
        <DefaultRoute
          exact
          path="/perfil"
          protected={true}
          component={ProfilePage}
        />
        <DefaultRoute exact path="/filtro/:type/" component={DynamicFilter} />
        <DefaultRoute
          exact
          path="/productos/:type/:url/"
          component={BookDetail}
        />
        <DefaultRoute exact path="/nosotros" component={Nosotros} />
        <DefaultRoute exact path="/aviso-legal" component={Legals} />
        <DefaultRoute
          exact
          path="/devoluciones-y-anulaciones"
          component={Refund}
        />
        <DefaultRoute exact path="/condiciones" component={Conditions} />
        <DefaultRoute
          exact
          path="/buscador-avanzado"
          component={SearchAdvanced}
        />
        <DefaultRoute exact path="/favoritos" component={FavoritesPage} />
        <DefaultRoute exact path="/blog" component={PostList} />
        <DefaultRoute
          exact
          path="/blog/post/:year/:month/:day/:slug"
          component={Post}
        />
        <DefaultRoute
          exact
          path="/recuperar-contrasena/:uid/:token"
          component={RecoverPage}
        />
        <DefaultRoute
          exact
          protected={true}
          path="/mis-pedidos"
          component={Pedidos}
        />
        <DefaultRoute
          exact
          protected={true}
          path="/mis-pedidos/:pk/"
          component={OrderDetail}
        />
        <DefaultRoute
          exact
          protected={false}
          path="/cafeteria"
          component={Cafeteria}
        />
        <DefaultRoute path="*" protected={false} component={NotFound} />
        {/* <NotFound /> */}
        {/* </DefaultRoute> */}
        {/* <Route path="*">
          <NotFound />
        </Route> */}
      </Switch>
    </Router>
  )
}

export default MainRoute

import React, { useState } from 'react'
import { Row, Col, Container, Button, Form } from 'react-bootstrap'

import { postSubscriptionEmail } from '../../api/company'
import { emailRegex } from '../../constans'
import './subscriptionForm.scss'

const SubscriptionForm = () => {
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [registerSuccess, setRegisterSuccess] = useState(false)

  const registerEmail = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setIsLoading(true)
      event.preventDefault()
      const emailValid = emailRegex.test(email)
      if (emailValid) {
        setFeedback('')
        setIsLoading(false)
        const payload = { email }
        const { status, data } = await postSubscriptionEmail(payload)
        if (status === 201) {
          setRegisterSuccess(true)
          setIsLoading(false)
        } else if (status === 400) {
          setFeedback('Su email ya se encuentra registrado')
          setRegisterSuccess(false)
          setIsLoading(false)
        } else {
          if (status !== 201) {
            setFeedback('Error interno intentar mas tarde')
            setIsLoading(false)
          }
        }
      } else if (!emailValid) {
        setFeedback('Ingrese una dirección de correo válida.')
        setIsLoading(false)
      }
    }
  }

  return (
    <Container fluid>
      <Row className="subscription-row">
        <Row>
          <Col lg={12}>
            <h3>
              Suscríbase al Boletín y entérate de todas nuestras novedades!
            </h3>
          </Col>
          {registerSuccess ? (
            <Col className="alert-success" lg={12}>
              <span>Su email ha sido registrado con éxito.</span>
            </Col>
          ) : null}
          <span className="feedback">{feedback}</span>
        </Row>

        <Col lg={12}>
          <Form onKeyDown={registerEmail}>
            <Row className="align-items-center">
              <Col lg={12}>
                <Col lg={11} xs={11}>
                  <Form.Control
                    id="inlineFormInput"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col lg={3} md={3} sm={4} xs={11}>
                  <Button onClick={registerEmail}>Suscribirse</Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default SubscriptionForm

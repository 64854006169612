import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// own components
import ItemList from '../../components/itemList/item'

const ListHome = (props) => {
  const { data } = props

  function preloadImages(element) {
    let img = new Image()
    img.src = element
  }

  useEffect(() => {
    if (data.productos) {
      const productImgs = data.productos.map(function (x) {
        return x.get_url
      })
      productImgs.forEach((element) => preloadImages(element))
    }
  }, [])

  return (
    <section className="margin-bottom-md list-section">
      <Row className="mt-5">
        <Col xs={12} sm={10}>
          <h3>{data.nombre}</h3>
        </Col>
        <Col xs={12} sm={2} className="v-center">
          <Link className="link-more" to={`/lista/${data.url}`}>
            Ver todo
          </Link>
        </Col>
      </Row>
      <Row>
        {data.productos.map((item, i) => (
          <Col md={4} lg={3} xs={10} sm={6} key={i} style={{ marginTop: 5 }}>
            <ItemList key={i} data={item} />
          </Col>
        ))}
      </Row>
    </section>
  )
}

export default ListHome

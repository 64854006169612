import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, ListGroup } from 'react-bootstrap'
import { Form, Alert } from 'react-bootstrap'
import {
  StepperAction,
  StepperContent,
  StepperContext,
} from 'react-material-stepper'

import { COMPANY } from '../../constans'
import Address from './address'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import './cart.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import delay from 'delay'
import { userSelector } from '../../slices/user'
import { companySelector } from '../../slices/company'
import {
  cartSelector,
  shippingFree,
  setShippingAddress,
  setBillingAddress,
  setStorePickup,
} from '../../slices/cart'
import { settingStorePickup } from '../../api/ecommerce'
import { getCountries, getProvinces, getLocalitiesByProvince } from "../../api/company"
import { saveChanges } from '../../api/users'
import { FullFormSchema, ShippingFormSchema } from '../../utils/formikValidators'
import { InputCustom } from "../../components/input";
import { Autocomplete } from '../../components/autocomplete'
import { UserContext } from '../../context/userContext'

const ShippingAddress = () => {
  const dispatch = useDispatch()
  const {
    shippingAddress,
    billingAddress,
    storePickup,
    shippingCosts,
  } = useSelector(cartSelector)
  const { isLogged } = useSelector(userSelector)
  const { direccion_completa, name } = useSelector(companySelector)
  const { showSignUpModal, setIsRegister } = useContext(UserContext)
  const { resolve, goAt, getData } = React.useContext(StepperContext)
  const [loadAddress, setLoadAddress] = useState(false)
  const [defaultActiveKey, setDefaultActiveKey] = useState('send')
  const [shippingData, setShippingData] = useState({});
  const [addBillingInfo, setAddBillingInfo] = useState(false);
  const [failSaveSend, setFailSaveSend] = useState(false);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([])
  const [localities, setLocalities] = useState([])
  const [allLocalities, setAllLocalities] = useState([])
  const [postalCodes, setPostalCode] = useState([])
  const [failSave, setFailSave] = useState(true);
  const [errorSave, setErrorSave] = useState('');
  const [duplicatedData, setDuplicatedData] = useState(false);
  const [logged, setLogged] = useState(isLogged)

  const initialValues = {
    shippingAddress: {
      address: shippingAddress?.address ? shippingAddress?.address : "",
      country: shippingAddress?.country ? shippingAddress?.country : "",
      phone: shippingAddress?.phone ? shippingAddress?.phone : "",
      province: shippingAddress?.province ? shippingAddress?.province : "",
      locality: shippingAddress?.locality ? shippingAddress?.locality : "",
      postal_code: shippingAddress?.postal_code ? shippingAddress?.postal_code : "",
      email: shippingAddress?.email ? shippingAddress?.email : "",
      first_name: shippingAddress?.first_name ? shippingAddress?.first_name : "",
      last_name: shippingAddress?.last_name ? shippingAddress?.last_name : "",
    },
    billingAddress: {
      address: billingAddress?.address ? billingAddress?.address : "",
      country: billingAddress?.country ? billingAddress?.country : "",
      phone: billingAddress?.phone ? billingAddress?.phone : "",
      province: billingAddress?.province ? billingAddress?.province : "",
      locality: billingAddress?.locality ? billingAddress?.locality : "",
      postal_code: billingAddress?.postal_code ? billingAddress?.postal_code : "",
      email: billingAddress?.email ? billingAddress?.email : "",
      first_name: billingAddress?.first_name ? billingAddress?.first_name : "",
      last_name: billingAddress?.last_name ? billingAddress?.last_name : "",
    }
  }

  const validationSchema = () => {
    let schema = ShippingFormSchema
    if (addBillingInfo) {
      schema = FullFormSchema
    }
    return schema
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      handleChangeSave(values)
      dispatch(setBillingAddress(formik.values.billingAddress))
      dispatch(setShippingAddress(formik.values.shippingAddress))
    }
  })

  const handleChangeSave = async (values) => {
    let payload = {
      name: values.shippingAddress?.first_name,
      last_name: values.shippingAddress?.last_name,
      email: values.shippingAddress?.email,
      phone: values.shippingAddress?.phone
    }
    payload.direccion_envio = values.shippingAddress ? values.shippingAddress : null
    payload.direccion_facturacion = values.billingAddress ? values.billingAddress : null
    const rs = await saveChanges(payload)
    if (rs.status === 200) {
      console.log(rs.status);
      setFailSave(false)
    } else {
      console.log(rs.status);
      setFailSave(true)
    }
  }

  const duplicateData = () => {
    for (let i in initialValues.shippingAddress) {
      formik.setFieldValue(
        `billingAddress.${i}`,
        formik.values.shippingAddress[i]
      )
    }
    setDuplicatedData(!duplicateData)
  }

  useEffect(() => {
    window.document.title = 'Carrito de compras | ' + COMPANY
    // setShippingData(shippingAddress)
    if (storePickup) {
      setDefaultActiveKey('pickUp')
    } else {
      setDefaultActiveKey('send')
    }
    setLoadAddress(true)
    //eslint-disable-next-line
  }, [shippingAddress, storePickup])

  const back = () => goAt('items')

  const next = () => goAt('billing')

  const changeTypeSend = async (value) => {
    const valuePickUp = value === 'pickUp' || value === true ? true : false
    dispatch(setStorePickup(valuePickUp))
    dispatch(shippingFree(valuePickUp))
    if (isLogged) {
      try {
        const { status, data } = await settingStorePickup({
          store_pickup: valuePickUp,
        })
        if (status === 200) {
          setFailSaveSend(false)
        } else {
          setFailSaveSend(true)
          await delay(2500)
          changeTypeSend(valuePickUp)
        }
      } catch (error) {
        console.log('error')
        setErrorSave('Algo ha ido mal, intentando reestablecer conexión.')
        setFailSaveSend(true)
        await delay(2500)
        changeTypeSend(valuePickUp)
        setErrorSave('')
      }
    } else {
      dispatch(setStorePickup(valuePickUp))
      dispatch(shippingFree(valuePickUp))
    }
  }

  useEffect(()=>{
    changeTypeSend()
  }, [])

  useEffect(()=>{
    if (logged !== isLogged) {
      back()
    }
  }, [isLogged])

  useEffect(() => {
    setShippingData(shippingAddress)
    async function handleCountries() {
      const rs = await getCountries();
      if (rs.status === 200) {
        const c = rs.data.map((item) => ({
          value: item.codigo,
          label: item.nombre,
        }));
        setCountries(c);
      }
    }
    handleCountries();
  }, []);

  const getAllProvincesByCountry = async (query) => {
    try {
      const { data } = await getProvinces(query)
      const resultsArray = data.map((result) => ({
        label: result['nombre'],
        value: result['id'],
      }))
      setProvinces(resultsArray)
    } catch (e) {}
  }

  const getAllLocalitiesByProvince = async (query) => {
    try {
      const { data } = await getLocalitiesByProvince(query)
      const resultsArray = data.map((result) => ({
        label: result['nombre'].charAt(0).toUpperCase() + result['nombre'].slice(1).toLowerCase(),
        value: result['cod_postal'].charAt(0).toUpperCase() + result['cod_postal'].slice(1).toLowerCase(),
      }))
      setAllLocalities(resultsArray)

      let localities = resultsArray
        .map((item) => item.label)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((result) => ({
          label: result,
          value: result,
        }))
      setLocalities(localities)
    } catch (e) {}
  }

  useEffect(() => {
    getAllProvincesByCountry(formik.values.shippingAddress?.country)
    //eslint-disable-next-line
  }, [])

  const changeCountry = (value) => {
    getAllProvincesByCountry(value.value)
    formik.setValues({
      ...formik.values,
      country: value.label,
      province: '',
      postal_code: '',
      locality: '',
    })
  }

  const changeProvince = (value) => {
    getAllLocalitiesByProvince(value.value)
    formik.setValues({
      ...formik.values,
      shippingAddress: {
        ...formik.values.shippingAddress,
        province: value.label,
        locality: '',
        postal_code: '',
      }
    })
  }

  const changeLocality = (value) => {
    formik.setValues({
      ...formik.values,
      shippingAddress: {
        ...formik.values.shippingAddress,
        locality: value.label,
        postal_code: '',
      }
    })
    getAllPostalCode(value.value)
  }

  const getAllPostalCode = async (query) => {
    let resultsArray = allLocalities
      .filter((result) => result['label'] === query)
      .map((result) => ({
        label: result['value'],
        value: result['label'],
      }))
    setPostalCode(resultsArray)
  }

  if (isLogged) {
    return (
      <StepperContent
        actions={
          <>
            <StepperAction
              className="button button--outline button--primary"
              type="button"
              onClick={back}
            >
              Anterior
            </StepperAction>
            {failSaveSend ? (
              <StepperAction
                align="right"
                variant="danger"
                // disabled={ failSave && !storePickup }
                // onClick={next}
              >
                Error de conexión
              </StepperAction>
            ) : (
              <StepperAction
                type="submit"
                align="right"
                disabled={ failSave && !storePickup }
                onClick={next}
              >
                Siguiente
              </StepperAction>
            )}
          </>
        }
      >
        <div className="titleResume">
          ¿Cómo te gustaría recibir tu pedido?
        </div>
        <div style={{ width: '100%', height: '30px' }}>
          <hr />
        </div>
        {errorSave !== '' ? (
          <Row className="alert-danger">
            <span>{errorSave}</span>
          </Row>
        ) : null}
        {defaultActiveKey && (
          <Tabs defaultActiveKey={defaultActiveKey} onSelect={changeTypeSend}>
            <Tab
              value={'send'}
              eventKey="send"
              title="Quiero que me lo envíen"
            >
              <br />
              {loadAddress && (
                <>
                  <form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col md={4}>
                        <InputCustom
                          id="first_name"
                          name={`shippingAddress.first_name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Nombre"
                          value={formik.values.shippingAddress?.first_name}
                          placeholder={'Ingrese su nombre'}
                          isInvalid={formik.errors.shippingAddress?.first_name && formik.touched.shippingAddress?.first_name}
                          error={formik.errors.shippingAddress?.first_name}
                        />
                      </Col>
                      <Col md={4}>
                        <InputCustom
                          id="last_name"
                          name={`shippingAddress.last_name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Apellido"
                          value={formik.values.shippingAddress?.last_name}
                          placeholder={'Ingrese su apellido'}
                          isInvalid={formik.errors.shippingAddress?.last_name && formik.touched.shippingAddress?.last_name}
                          error={formik.errors.shippingAddress?.last_name}
                        /> 
                      </Col>                  
                      <Col md={4}>
                        <InputCustom
                          id="email"
                          name={`shippingAddress.email`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Correo"
                          type="email"
                          placeholder={'Ingrese su correo'}
                          isInvalid={formik.errors.shippingAddress?.email && formik.touched.shippingAddress?.email}
                          value={formik.values.shippingAddress?.email}
                          error={formik.errors.shippingAddress?.email}
                        />
                      </Col>
                      <Col md={4}>
                        <InputCustom
                          id="phone"
                          name={`shippingAddress.phone`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Teléfono"
                          placeholder={'Ingrese su teléfono'}
                          value={formik.values.shippingAddress?.phone}
                          isInvalid={formik.errors.shippingAddress?.phone && formik.touched.shippingAddress?.phone}
                          error={formik.errors.shippingAddress?.phone}
                        />
                      </Col>
                      <Col md={4}>
                        <InputCustom
                          id="address"
                          name={`shippingAddress.address`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Dirección"
                          placeholder={'Ingrese su dirección'}
                          value={formik.values.shippingAddress?.address}
                          isInvalid={formik.errors.shippingAddress?.address && formik.touched.shippingAddress?.address}
                          error={formik.errors.shippingAddress?.address}
                        />
                      </Col>
                      <Col md={4}>
                        <Autocomplete
                          id="country"
                          isDisabled={true}
                          required
                          label="País"
                          asyncFetch={countries}
                          onSelect={(value) => {
                            changeCountry(value)
                          }}
                          placeholder={'País'}
                          inputValue={{ label: 'España', value: 'España' }}
                        />
                      </Col>
                      <Col md={4}>
                        <Autocomplete
                          id="province"
                          required
                          label="Provincia"
                          asyncFetch={provinces}
                          onSelect={(value) => {
                            changeProvince(value)
                          }}
                          onBlur={formik.handleBlur}
                          placeholder={'Provincia'}
                          inputValue={{ 
                            label: formik.values.shippingAddress?.province,
                            value: formik.values.shippingAddress?.province
                          }}
                          showError={
                            formik.errors.shippingAddress?.province 
                            && formik.touched.shippingAddress?.province
                          }
                          errorMessage={formik.errors.shippingAddress?.province}
                        />
                      </Col>
                      <Col md={4}>
                        <Autocomplete
                          id="locality"
                          required
                          label="Localidad"
                          asyncFetch={localities}
                          onSelect={(value) => changeLocality(value)}
                          onBlur={formik.handleBlur}
                          placeholder={'Localidad'}
                          inputValue={{ 
                            label: formik.values.shippingAddress?.locality,
                            value: formik.values.shippingAddress?.locality
                          }}
                          showError={
                            formik.errors.shippingAddress?.locality 
                            && formik.touched.shippingAddress?.locality
                          }
                          errorMessage={formik.errors.shippingAddress?.locality}
                        />
                      </Col>
                      <Col md={4}>
                        <InputCustom
                          id="postal_code"
                          name={`shippingAddress.postal_code`}
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Código Postal"
                          placeholder={'Código postal'}
                          value={formik.values.shippingAddress?.postal_code}
                          isInvalid={formik.errors.shippingAddress?.postal_code && formik.touched.shippingAddress?.postal_code}
                          error={formik.errors.shippingAddress?.postal_code}
                        />
                      </Col>
                    </Row>
                    <div>
                      <Form.Check 
                        className="mt-5 mb-3 ml-2"
                        type='checkbox'
                        value={addBillingInfo}
                        onChange={()=> setAddBillingInfo(!addBillingInfo)}
                        label='Agregar datos de facturación'
                      />
                    </div>
                    {addBillingInfo && (
                      <>
                        <Form.Check 
                          className="mt-2 mb-3 ml-2"
                          type='checkbox'
                          value={duplicatedData}
                          onChange={()=> duplicateData()}
                          label='Mismos datos del envío'
                        />
                        <Address formik={formik} userInvalid={false} />
                      </>
                    )}
                    <div className="w-100 d-flex justify-content-end">
                      <Button
                        className="white-btn py-2 px-0 mt-3"
                        style={{ width: "35%" }}
                        type="submit"
                        onClick={() => {
                          if (isEmpty(formik.errors)) {
                            formik.handleSubmit();
                          }
                        }}
                      >
                        GUARDAR DATOS
                      </Button>
                    </div>
                  </form>
                </>
              )}
              <br />
            </Tab>
            <Tab
              value={'pickUp'}
              eventKey="pickUp"
              title="Lo recogeré en la tienda"
            >
              <div className="mt-4 mb-4 ml-5">
                <br />
                <p> El pedido deberá ser retirado en <strong>{name.toUpperCase()}</strong> </p>
                <p> Dirección: <strong>{direccion_completa}</strong> </p>
                <br />
              </div>
            </Tab>
          </Tabs>
        )}
      </StepperContent>
    )
  }  else {
    return (
      <Row className="px-3">
        <Col style={{paddingTop: '20px', paddingBottom: '10px'}}>
        <Alert className="flex-column" show={true} variant="info" style={{paddingTop: '20px', paddingBottom: '30px'}}>
          <Alert.Heading>Identificación necesaria</Alert.Heading>
          <p>
            Para continuar el proceso de compra hay que disponer de una cuenta.
          </p>
          <hr />
          <div className="d-flex justify-content-start">
            <strong 
              onClick={() => {showSignUpModal(true); setIsRegister(false)}}
              style={{cursor: 'pointer', color: '#0069d9'}}
              className="ml-2"
            > 
              INICIAR SESIÓN 
            </strong>
            &nbsp; /
            <strong 
              onClick={() => {showSignUpModal(true); setIsRegister(true)}}
              style={{cursor: 'pointer', color: '#0069d9'}}
              className="ml-2"
            > 
              REGISTRARME
            </strong>
          </div>
        </Alert>
        </Col>
      </Row>
    )
  }
}

export default ShippingAddress

import { methodGet, methodPost } from './methods'
import { API_INFO } from '../constans'

export async function getDetailOrder(id) {
  const url = API_INFO + 'storehouse/orders/' + id + '/'
  return methodGet(url)
}

export async function sendClaim(payload) {
  const url = API_INFO + 'storehouse/claims/'
  return methodPost(url, payload)
}

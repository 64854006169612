import { methodGet, methodDelete } from './methods'
import { API_INFO } from '../constans'

export async function getDetailProduct(id, query) {
  const url = API_INFO + 'products/products/' + id + '/'
  return methodGet(url, query)
}

export async function getRelatedProduct(id, query) {
  const url = API_INFO + `products/products/${id}/get_releated_products/`
  return methodGet(url, query)
}

export async function getPublishedCountries(query) {
  const url = API_INFO + `products/products/get_published_countries/`
  return methodGet(url, query)
}

export async function getLanguageProducts(query) {
  const url = API_INFO + `products/products/get_lan_products/`
  return methodGet(url, query)
}

export async function searchProduct(query) {   // new version (Elasticsearch), to replace searchFilterProduct
  const url = API_INFO + `products/products/search_products/`
  return methodGet(url, query)
}

export async function searchFilterProduct(query) {
  const url = API_INFO + `products/products/filter_products/`
  return methodGet(url, query)
}

export async function listAudiencia() {
  const url = API_INFO + 'products/products/get_clasification_products/'
  return methodGet(url, {})
}

export const slidersClient = () => {
  const url = API_INFO + 'products/sliders-client'
  return methodGet(url, {})
}

export const getRangeDatePublished = () => {
  const url = API_INFO + 'products/products/get_range_fecha_public/'
  return methodGet(url, {})
}

export async function removeItemFav(productUrl) {
  const url = API_INFO + `products/products/${productUrl}/remove_saved_user/`
  return methodDelete(url, {})
}

export async function getDetailAuthor(slug) {
  const url = API_INFO + `products/authors/${slug}/`
  return methodGet(url, {})
}

export async function getProductsByAuthor(slug, page) {
  const url = API_INFO + `products/authors/${slug}/get-products?page=${page}`
  return methodGet(url, {})
}

export async function getDetailEditorial(slug) {
  const url = API_INFO + `products/editorials/${slug}/`
  return methodGet(url, {})
}

export async function getProductsByEditorial(slug, page) {
  const url = API_INFO + `products/editorials/${slug}/get-products?page=${page}`
  return methodGet(url, {})
}

export async function getStockProviders(id) {
  const url = API_INFO + `products/products/${id}/get_stock_cegal`
  return methodGet(url, {})
}

import React, { useState, useEffect } from 'react'
import 'react-material-stepper/dist/react-stepper.css'
import { Row, Col, Container, Jumbotron } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import delay from 'delay'

import { cartSelector, clear } from '../../slices/cart'
import { createOrder } from '../../api/ecommerce'
import { useLocation } from 'react-router-dom'
import './cart.scss'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const OkPayment = () => {
  const query = useQuery()
  const ds_merchant_parameters = query.get('Ds_MerchantParameters')
  const ds_signature = query.get('Ds_Signature')

  const {
    billingAddress,
    shippingAddress,
    storePickup,
    items,
    shippingCosts,
  } = useSelector(cartSelector)
  const [header, setHeader] = useState('En proceso')
  const [msg, setMsg] = useState('Procesando pedido')
  const [failApiCall, setFailApiCall] = useState(false)

  const dispatch = useDispatch()

  const confirmOrder = async () => {
    if (items.length > 0) {
      const payload = {
        forma_pago: 4,
        billing_address: billingAddress? billingAddress : {},
        shipping_address: shippingAddress? shippingAddress : {},
        is_new_shipping_address: false,
        store_pickup: storePickup,
        items,
        shipping_cost: shippingCosts,
        finish_process: true,
        ds_merchant_parameters,
        ds_signature,
      }
      try {
        const { status, data } = await createOrder(payload)
        if (status === 201) {
          setFailApiCall(false)
          setHeader('Pedido procesado con éxito')
          setMsg('Recibirás un email con los datos del pedido')
          dispatch(clear())
        } else {
          setHeader('Error al procesar el pedido')
          setMsg('Ha ocurrido un error al generar el pedido, intentando nuevamente.')
          setFailApiCall(true)
          await delay(3000)
          confirmOrder()
        }
      } catch (error) {
        setHeader('Error al procesar el pedido')
        setMsg('Ha ocurrido un error al generar el pedido')
        setFailApiCall(true)
        await delay(3000)
        confirmOrder()
      }
    } else {
      setHeader('Carrito vacío')
      setMsg('No hay elementos en el carrito')
    }
  }

  useEffect(() => {
    confirmOrder()
  }, [])

  const apiError = () => {
    return (
      <div style={{textAlign: 'center'}}> 
        <div>
          <MdErrorOutline style={{color: 'red', fontSize: '28px'}} />
        </div>
        <div>
          Error de conexión
        </div>
        <div style={{color: '#0000EE', cursor: 'pointer'}}>
          Procesando solicitud nuevamente.
        </div>
      </div>
    )
  }

  return (
    <Container>
      <Row>
        <Col md={12} className="title">
          <h2>Carrito de Compras</h2>
          <span className="divider-title"></span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Jumbotron style={{ textAlign: 'center' }}>
            <h3>{header}</h3>
            <p>{msg}</p>
            {failApiCall &&
              apiError()
            }
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  )
}

export default OkPayment

import React, { useState, useEffect } from 'react';
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineInfo } from 'react-icons/ai';

//api
import { getMyOrders } from '../../api/users'

const Orders = () => {
  const [orders, setorders] = useState([])
  const [loading, setloading] = useState(false)
  
  useEffect(() => {
    (async () => {
      setloading(true)
      try {
        const { data } = await getMyOrders()
        setorders(data)
        setloading(false)
      } catch (e) {
        setloading(false)
      }
    })()
  }, [])
  return (
    <Container>
      <Row>
        <Col className="title" md={12}>
          <h2 >Mis pedidos</h2>
          <span className="divider-title"></span>
        </Col>
        <Col md={12}>
          {
            orders.length > 0 ? (
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col md={2} className="tableLeft">
                      Fecha:
                    </Col>
                    <Col md={2} className="tableLeft">
                      Estado:
                    </Col>
                    <Col md={2} className="tableLeft">
                      Cantidad de Items
                    </Col>
                    <Col md={1} className="tableLeft">
                      IVA
                    </Col>
                    <Col md={1} className="tableLeft">
                      Total
                    </Col>
                    <Col md={2} className="tableLeft">
                      Forma Pago
                    </Col>
                    <Col md={2} className="tableLeft">
                      Detalle
                    </Col>
                  </Row>
                </ListGroup.Item>
                {
                  orders.map((item, i) => (
                    <ListGroup.Item>
                      <Row>
                        <Col md={2}>
                          {item.fecha}
                        </Col>
                        <Col md={2}>
                          {item.status}
                        </Col>
                        <Col md={2}>
                          {item.cantidad_total}
                        </Col>
                        <Col md={1}>
                          {item.iva.toFixed(2)} €
                        </Col>
                        <Col md={1}>
                          {item.total.toFixed(2)} €
                        </Col>
                        <Col md={2}>
                          {item.forma_pago_display}
                        </Col>
                        <Col md={2}>
                          <Link to={`/mis-pedidos/${item.id}/`}>
                            <button
                              aria-label="Ver pedido"
                              title="Ver pedido"
                              className="button button--icon button--outline-hover"
                            >
                              <AiOutlineInfo />
                            </button>
                          </Link>                          
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))
                }

              </ListGroup>
            ): <h1>No tienes pedidos</h1>
          }          
        </Col>
      </Row>

    </Container>
  )
}

export default Orders

import React from 'react'

// own components
import MenuMain from '../components/navbar/HeaderDefault.js'
import Footer from '../components/footer/FooterDefault.js'
import Cookies from '../components/cookiesAlert/cookies.js'
import { companySelector } from '../slices/company.js'
import { userSelector } from '../slices/user'
import { useSelector } from 'react-redux'

const DefaultLayout = (props) => {
  const {
    name,
    brandLogo,
    direccion_completa,
    telefono,
    email,
    redes_sociales,
    data,
    menus,
    has_subscription,
    refundText,
  } = useSelector(companySelector)
  const { isLogged } = useSelector(userSelector)

  return (
    <>
      <MenuMain brandLogo={brandLogo} menus={menus} isLogged={isLogged} />
      <Cookies />
      <main className="margin-md">{props.children}</main>
      <Footer
        name={name}
        brandLogo={brandLogo}
        direccion_completa={direccion_completa}
        telefono={telefono}
        email={email}
        redes_sociales={redes_sociales}
        data={data}
        menus={menus}
        has_subscription={has_subscription}
        refundText={refundText}
      />
    </>
  )
}

export default DefaultLayout

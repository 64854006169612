import React, { Suspense } from 'react'
import { useImage } from 'react-image'
import BrokenIcon from '../../assets/img/broken-image.svg'
import preview from '../../assets/img/preview.jpg'
import { Spinner } from 'react-bootstrap'

function Image({ title, url }) {
  const { src } = useImage({
    srcList: [url, preview],
  })

  const isBroken = src === BrokenIcon
  return (
    <div className="cover-container">
      <img
        alt={`Portada ${isBroken ? '(no encontrada)' : ''} del libro: ${title}`}
        src={src}
      />
    </div>
  )
}

const LoadingCover = () => (
  <div className="cover-container cover-container--loading">
    <Spinner animation="border" />
  </div>
)

export default function CoverImg({ title = '', src }) {
  return (
    <Suspense fallback={<LoadingCover />}>
      <Image title={title} url={src} />
    </Suspense>
  )
}

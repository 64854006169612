import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { AiOutlineClockCircle } from 'react-icons/ai'

// own api
import { getEvent } from '../../api/company'

// own components
import Error404 from '../../components/statusError/error404'

// own styles
import '../../assets/css/event.scss'

import { COMPANY, MONTH_DIS } from '../../constans'

const EventDetail = () => {
  const [event, setEvent] = useState(null)
  const [notFound, setNotFound] = useState(false)
  const [startMonth, setStartMonth] = useState(0)
  const [startDay, setStartDay] = useState(0)
  const [finishMonth, setFinishMonth] = useState(0)
  const [finishDay, setFinishDay] = useState(0)
  let { id } = useParams()

  useEffect(() => {
    async function handleLoadData() {
      const rs = await getEvent(id)
      if (rs.status === 200) {
        setEvent(rs.data)
        window.document.title = rs.data.title + ' | ' + COMPANY
      } else if (rs.status === 404) {
        setNotFound(true)
      }
    }
    handleLoadData()
  }, [id])

  useEffect(() => {
    if (notFound) {
      window.document.title = 'Página no encontrada | ' + COMPANY
    }
  }, [notFound])

  useEffect(() => {
    if (event !== null) {
      const { start_date, finish_date } = event
      setStartMonth(parseInt(start_date.split('-')[1]))
      setStartDay(parseInt(start_date.split('-')[2]))
      setFinishMonth(parseInt(finish_date.split('-')[1]))
      setFinishDay(parseInt(finish_date.split('-')[2]))
    }
  }, [event])

  return notFound ? (
    <Error404 />
  ) : event !== null ? (
    <Container className="event-container">
      <Row
        className="header"
        style={{ backgroundImage: `url('${event.cover}')`, zIndex: -1 }}
      />
      <Row className="margin-top-md">
        <Col md={3}>
          <h3>{event.title}</h3>
          <div className="date">
            {startMonth === finishMonth ? (
              <>
                <span className="month">{MONTH_DIS[startMonth - 1]}.</span>
                <span className="day">
                  {startDay !== finishDay ? (
                    <>
                      {startDay} - {finishDay}
                    </>
                  ) : (
                    <>{startDay}</>
                  )}
                </span>
              </>
            ) : (
              <span className="day">
                {MONTH_DIS[startMonth - 1]}. {startDay} -{' '}
                {MONTH_DIS[finishMonth - 1]}. {finishDay}
              </span>
            )}
          </div>
        </Col>
        <Col md={9}>
          <h6>
            <AiOutlineClockCircle
              className="margin-right-xs"
              style={{ fontSize: 25 }}
            />
            <b>Hora de inicio:</b> {event.start_time.substring(0, 5)}{' '}
          </h6>
          <p className="description">{event.description}</p>
        </Col>
      </Row>
    </Container>
  ) : null
}

export default EventDetail

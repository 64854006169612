import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Carousel,
  Button,
  Form,
  Alert,
} from 'react-bootstrap'
import { FaUtensils } from 'react-icons/fa'
import { getCSData, getBookingForm } from '../../api/company'
import cafeteria1 from '../../assets/img/cafeteria-1.jpg'
import cafeteria2 from '../../assets/img/cafeteria-2.jpg'
import style from './cafeteria.module.scss'
import formText from '../../utils/formText'
import { InputCustom } from '../../components/input/index'
import { emailRegex } from '../../constans'

import Head from '../../components/metatags_helmet/metatags'

const galleryImages = [cafeteria1, cafeteria2]

const Paragraph = (props) => {
  let content = `Como complemento a la librería hace unos años ya, decidimos poner en marcha un pequeño café y así cumplir nuestro deseo de que Ocho y Medio fuera un lugar de encuentro, donde la gente pudiera desde localizar las últimas novedades editoriales, hasta charlar con amigos, mientras toma un café, una cerveza, o un vino.`
  if (props.content && JSON.stringify(props.content) !== '{}') {
    content = props.content
  }
  const lines = content.split(/\r?\n/).filter((item) => item.length)
  const title = 'Cafetería'

  return (
    <div>
      <Head description={content} title={title} />
      {lines.map((line, index) => {
        if (index === 0)
          return (
            <p className="mt-4 text-wrap" key={index}>
              {line}
            </p>
          )
        else return <p key={index}>{line}</p>
      })}
    </div>
  )
}

const BookingForm = ({ props }) => {
  const [isSending, setIsSending] = useState(false)
  const [sentForm, setSentForm] = useState(false)
  const [hasRequestError, setHasRequestError] = useState(false)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [emailInvalid, setEmailInvalid] = useState('')
  const [messageInvalid, setMessageInvalid] = useState('')

  const validateForms = (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      const checkEmail = emailRegex.test(email)
      if (!checkEmail) {
        setEmailInvalid(true)
      } else {
        setEmailInvalid(false)
      }
      if (message.length > 300 || message.length < 10) {
        setMessageInvalid(true)
      } else {
        setMessageInvalid(false)
      }
      if (emailInvalid === false && messageInvalid === false) {
        sendBookingForm(event)
      }
    }
  }

  async function sendBookingForm(event) {
    setIsSending(true)
    setHasRequestError(false)
    const form = event.target
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    const values = {
      email: email,
      message: message,
    }
    try {
      const { status } = await getBookingForm(values)

      if (status !== 500) {
        setSentForm(true)
      } else {
        setHasRequestError(true)
      }
    } catch (error) {
      // console.log(error)
    }
    setIsSending(false)
  }

  if (sentForm) {
    return (
      <Col md={8} className="mt-2 mb-5 text-center">
        <strong>
          ¡Tu mensaje ha sido enviado exitosamente! Revisa el buzón de entrada
          de tu email.
        </strong>
      </Col>
    )
  }

  return (
    <Col md={8} className="mt-2 mb-5">
      {hasRequestError && (
        <Alert
          className="mb-4"
          variant="danger"
          dismissible
          onClose={() => setHasRequestError(false)}
        >
          Hubo un problema al intentar enviar este mensaje. Por favor, intenta
          de nuevo.
        </Alert>
      )}
      <Row>
        <div className="w-100" onKeyDown={validateForms}>
          <Form onSubmit={validateForms}>
            <Col md={7}>
              <InputCustom
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                placeholder={'Ingrese su email'}
                isInvalid={emailInvalid}
                error="Ingrese un email válido."
              />
            </Col>
            <Col md={12}>
              <InputCustom
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                as="textarea"
                label="Mensaje"
                placeholder={'Mensaje'}
                isInvalid={messageInvalid}
                error="Ingrese mensaje entre 10 y 300 caracteres."
              />
              <Button onClick={validateForms} disabled={isSending}>
                {isSending ? 'Enviando...' : 'Enviar'}
              </Button>
            </Col>
          </Form>
        </div>
      </Row>
    </Col>
  )
}

const CoffeeShopPage = () => {
  const [csText, setCSText] = useState({})
  const [csImage, setCSImage] = useState('')

  useEffect(() => {
    const getCoffeeShopData = async () => {
      try {
        const rs = await getCSData()
        if (rs.status === 200) {
          const { data } = rs
          setCSText(data.contenido)
          setCSImage(data.image_featured)
        }
      } catch (error) {}
    }

    getCoffeeShopData()
  }, [])

  return (
    <Container>
      <Row className="mainrow-about">
        <Col lg={6} xs={12}>
          <div>
            <h2>Café</h2>
            <hr />
          </div>
          <div>
            <Paragraph content={csText} />
            <Button as="a" href="#reservaciones">
              Contáctanos
            </Button>
          </div>
        </Col>
        <Col lg={6} xs={12}>
          <Carousel controls touch interval={null} className={style.gallery}>
            {galleryImages.map((image) => (
              <Carousel.Item key={image}>
                {/* <figure> */}
                <img
                  src={image}
                  alt=""
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                  }}
                />
                {/* </figure> */}
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col className="margin-top-md">
          <hr />
          <h3 className="text-center">
            Menú <FaUtensils aria-hidden="true" />
          </h3>
          <hr />
        </Col>
      </Row>
      <Row className={`justify-content-md-center ${style.menu}`}>
        <Col md={8}>
          <h4>Aperitivos</h4>
          <ul>
            <li>
              <span>
                <strong>Gilda </strong>
                (1946)
              </span>
              <span> Gildas 1,00€</span>
            </li>
            <li>
              <span>
                <strong>En grata compañía </strong>
                (1961) Vermú y una de nuestras Gildas
              </span>
              <span> 3,50€</span>
            </li>
          </ul>
        </Col>
        <Col md={8}>
          <h4>Entrantes</h4>
          <ul>
            <li>
              <span>
                <strong>Salmorejo </strong>
              </span>
              <span>3,50€</span>
            </li>
          </ul>
        </Col>
        <Col md={8}>
          <h4>Tostas</h4>
          <ul>
            <li>
              <span>
                <strong>Wilder </strong>
                (Queso feta, tomate, albahaca)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Orson Welles </strong>
                Welles (Ventresca y tomate)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Imanol Uribe </strong>
                (Anchoas y guacamole)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Bergman </strong>
                (Salmón ahumado, queso cremoso y eneldo)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>John Huston </strong>
                (Bacalao ahumado, guacamole y cilantro)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Buñuel </strong>
                (Jamón Ibérico y tomate)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Ford </strong>
                (Rúcula, queso de cabra y mermelada de tomate)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Berlanga </strong>
                (Jamón Ibérico y queso curado)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Spielberg </strong>
                (Sardinas ahumada con base de guacamole)
              </span>
              <span>6,50€</span>
            </li>
            <li>
              <span>
                <strong>Lubitsch </strong>
                (Queso feta, tomate, albahaca)
              </span>
              <span>6,50€</span>
            </li>
          </ul>
        </Col>
        <Col md={8}>
          <h4>Ensaladas frías</h4>
          <ul>
            <li>
              <span>
                <strong>Fellini, 8 1/2 </strong>
                (1963) Naranja o tomate, bacalao ahumado con aceita de oliva y
                aceitunas negras.
              </span>
              <span>11,00 €</span>
            </li>
            <li>
              <span>
                <strong>Mi gran boda griega </strong>
                (2002) Ensalada de queso feta, brotes tiernos, crema al vinagre
                de Módena, tomate cherry y olivas negras.
              </span>
              <span>13,00 €</span>
            </li>
            <li>
              <span>
                <strong>De repente el último verano </strong>
                (1959) Ventresca de atún, cebolla y tomate.
              </span>
              <span>12,00 €</span>
            </li>
            <li>
              <span>
                <strong>Gritos y Susurros </strong>
                (1972) Rúcula, guacamole y salmón ahumado.
              </span>
              <span>9,00 €</span>
            </li>
            <li>
              <span>
                <strong>Blow </strong>
                (2001) Ensalada de burrata de la Sierra de Guadarrama.
              </span>
              <span>9,00 €</span>
            </li>
            <li>
              <span>
                <strong>Amarcord </strong>
                (1973) Sardinas ahumadas, brotes tiernos, aceitunas negras y
                bolitas de mozzarella)
              </span>
              <span>11,00 €</span>
            </li>
          </ul>
        </Col>
        <Col md={8}>
          <h4>Hamburguesas</h4>
          <ul>
            <li>
              <span>
                <strong>Pulp fiction </strong>
                (1994) Ternera, queso cheddar, brotes tiernos, tomate y cebolla
                caramelizada.
              </span>
              <span>7,00€</span>
            </li>
          </ul>
        </Col>
        <Col md={8}>
          <h4>Raciones </h4>
          <ul>
            <li>
              <span>
                <strong>Almodóvar </strong>
                (Patatas bravas)
              </span>
              <span>7,00€</span>
            </li>
            <li>
              <span>
                <strong>Bienvenido Mr.Marshall </strong>
                (1953) Jamón Ibérico 15,00€ y ½ ración.
              </span>
              <span>8,50€</span>
            </li>
            <li>
              <span>
                <strong>Delicatesen </strong>
                (1991) Queso curado manchego de oveja 14,00€ y ½ ración.
              </span>
              <span>8,00€</span>
            </li>
            <li>
              <span>
                <strong>Caramel </strong>
                (2007) Hummus.
              </span>
              <span>7,00€</span>
            </li>
            <li>
              <span>
                <strong>Volver </strong>
                (2006) Pincho de tortilla con cebolla.
              </span>
              <span>3,50€</span>
            </li>
          </ul>
        </Col>
      </Row>
      <hr />
      <Row id="reservaciones" className="justify-content-md-center">
        <Col md={8} className="margin-top-md">
          <h2 className="text-left">Reservas o Comentarios</h2>
          <hr />
        </Col>
        <BookingForm />
      </Row>
    </Container>
  )
}

export default CoffeeShopPage

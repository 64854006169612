import { methodGet } from './methods'
import { API_INFO } from '../constans'

export const getPosts = async (query) => {
  return methodGet(API_INFO + 'blog/posts/', query)
}

export const getPost = async ({ year, month, day, slug }) => {
  return methodGet(API_INFO + `blog/posts/${year}/${month}/${day}/${slug}`)
}

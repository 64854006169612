import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { Navbar, Nav, Row, Col, NavDropdown, Button } from 'react-bootstrap'
import { AiOutlineUser, AiOutlineShoppingCart } from 'react-icons/ai'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import delay from 'delay'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

//redux
import { cartSelector, clear, clearShippingData } from '../../slices/cart'
import { useSelector } from 'react-redux'

// context
import { UserContext } from '../../context/userContext'

// own components
import SearchBar from './SearchBar'
import LoginModal from '../../pages/login'
import DropMenuLogged from './DropMenuLogged'

//helpers
import { unSetLocal } from '../../helpers'

//api
import { logoutApi } from '../../api/login'
import { logoutSuccess } from '../../slices/user'

const MenuMain = ({ brandLogo, menus, isLogged }) => {
  const { isSignUpModalOpen, showSignUpModal, isRegister } = useContext(UserContext)
  const [showToast, setShowToast] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const handleCloseModal = useCallback(
    () => showSignUpModal(false),
    [showSignUpModal]
  )
  const handleShowModal = useCallback(
    () => showSignUpModal(true),
    [showSignUpModal]
  )

  const { countItems, lastAddedItem, lastRemovedItem, isIncrease } =
    useSelector(cartSelector)
  const previousCounterValue = useRef(countItems)
  const dispatch = useDispatch()
  const headerRef = useRef()
  const history = useHistory()

  const [counterAnimProps, set] = useSpring(() => ({
    backgroundColor: 'white',
    transform: 'translateY(0px)',
    config: {
      tension: 160,
      friction: 20,
    },
  }))

  useScrollPosition(
    ({ currPos }) => {
      setIsSticky(Math.abs(currPos.y) > headerRef.current.offsetHeight)
    },
    [],
    null,
    null,
    200
  )

  useEffect(() => {
    ;(async () => {
      if (countItems !== previousCounterValue.current) {
        set({
          backgroundColor: 'rgb(145, 214, 255)',
          transform: 'translateY(-2px)',
        })
        await delay(180)
        setShowToast(true)
        set({ backgroundColor: 'white', transform: 'translateY(0px)' })
      }
    })()
  }, [countItems])

  useEffect(() => {
    ;(async () => {
      if (showToast) {
        await delay(4000)
        setShowToast(false)
      }
    })()
  }, [showToast])

  const closeSession = async () => {
    const { status } = await logoutApi({
      refresh_token: localStorage.getItem('refresh'),
    })
    if (status === 200) {
      unSetLocal('access')
      unSetLocal('refresh')
      unSetLocal('permissions')
      unSetLocal('type_user')
      dispatch(clear())
      dispatch(clearShippingData())
      dispatch(logoutSuccess())
      history.push('/')
    }
  }

  return (
    <header
      className={`header  container-full ${isSticky ? 'header--sticky' : ''}`}
    >
      <div ref={headerRef} className={`container-full shadow-bottom`}>
        <div className="container">
          <Row className="header__container">
            <Col md={3} className="d-none d-md-block header__brand">
              <Link to={'/'}>
                <img alt="Logotipo Empresa" src={brandLogo} />
              </Link>
            </Col>
            <Col md={7} className="header__search">
              <SearchBar />
            </Col>
            <Col md={2} xs={6} className="d-none d-md-block header__actions">
              <Row
                as="nav"
                aria-label="menú de compras y usuario"
                className="justify-content-end align-items-center header__menu"
              >
                <ul>
                  <li>
                    <Link
                      to="/carrito"
                      aria-label={`Carrito de compras (${countItems} items)`}
                      className={`
                        header__button header__button--cart
                        ${countItems > 0 ? ' filled' : ''}
                      `}
                    >
                      <AiOutlineShoppingCart className="icon-md" />
                      {countItems > 0 && (
                        <animated.span
                          style={counterAnimProps}
                          className="cart-counter"
                        >
                          {countItems}
                        </animated.span>
                      )}
                      {showToast && (
                        <div className="cart-toast">
                          <span>
                            Has {isIncrease ? 'añadido ' : 'quitado '}
                            {isIncrease ? (
                              <strong>"{lastAddedItem}"</strong>
                            ) : (
                              <strong>"{lastRemovedItem}"</strong>
                            )}{' '}
                            {isIncrease ? 'al' : 'del'} carrito
                          </span>
                        </div>
                      )}
                    </Link>
                  </li>
                  {isLogged ? (
                    <li>
                      <DropMenuLogged
                        dropDirection="down"
                        closeSession={closeSession}
                      />
                    </li>
                  ) : (
                    <li>
                      <button
                        className="header__button header__button--user"
                        aria-label="usuario"
                        onClick={handleShowModal}
                        /*style={{ backgroundColor: '#d9dbde' }}*/
                      >
                        <AiOutlineUser role="none" className="icon-md" />
                      </button>
                    </li>
                  )}
                </ul>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-full shadow-bottom">
        <div className="container header__nav ">
          <Navbar className="bg-transparency header__main-nav" expand="md">
            <Row>
              <Col xs={12}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <img
                  className="d-none d-sm-inline d-md-none ml-4 logo--mobile"
                  height="50"
                  alt="logo"
                  src={brandLogo}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-sm-block d-md-none">
                <Row
                  role="menubar"
                  className="justify-content-end align-items-center"
                >
                  <Link
                    className="header__button header__button--cart"
                    to="/carrito"
                  >
                    <AiOutlineShoppingCart className="icon-md" />
                    {countItems > 0 && (
                      <animated.span
                        style={counterAnimProps}
                        className="cart-counter"
                      >
                        {countItems}
                      </animated.span>
                    )}
                    {showToast && (
                      <div className="cart-toast">
                        <span>
                          Has {isIncrease ? 'añadido ' : 'quitado '}
                          {isIncrease ? (
                            <strong>"{lastAddedItem}"</strong>
                          ) : (
                            <strong>"{lastRemovedItem}"</strong>
                          )}{' '}
                          {isIncrease ? 'al' : 'del'} carrito
                        </span>
                      </div>
                    )}
                  </Link>
                  {isLogged ? (
                    <DropMenuLogged
                      className="header__button header__button--logged"
                      dropDirection="left"
                      closeSession={closeSession}
                    />
                  ) : (
                    <button className="header__button header__button--user ml-2">
                      <AiOutlineUser
                        onClick={handleShowModal}
                        className="icon-md"
                      />
                    </button>
                  )}
                </Row>
              </Col>
            </Row>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="header-mobile-nav"
            >
              <Nav className="mx-auto">
                <Nav.Link href="/">Inicio</Nav.Link>
                {menus &&
                  menus.map((item, i) =>
                    item.submenus && item.submenus.items ? (
                      <NavDropdown title={item.name} key={i}>
                        {item.submenus.items.map((item2, i2) => (
                          <NavDropdown.Item
                            key={i2}
                            as={Link}
                            to={{ pathname: `/${item.slug}/${item2.url}/` }}
                          >
                            {item2.name}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ) : item.slug.indexOf('lista') !== -1 ? (
                      item.slug.includes('http') ? (
                        <a
                          href={item.slug}
                          className="nav-link"
                          target="_blank"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Nav.Link
                          key={i}
                          as={Link}
                          to={{ pathname: `/${item.slug}/` }}
                        >
                          {item.name}
                        </Nav.Link>
                      )
                    ) : (
                      <Nav.Link key={i} as={Link} to={item.slug}>
                        {item.name}
                      </Nav.Link>
                    )
                  )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {isSignUpModalOpen ? (
        <LoginModal 
          show={isSignUpModalOpen} 
          handleClose={handleCloseModal} 
          register={isRegister}
        />
      ) : null}
    </header>
  )
}

export default MenuMain

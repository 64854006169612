import React from 'react';
import { Card } from 'react-bootstrap';

import "../../assets/css/card.scss"

const CardCustom = (props) => {
  return (
    <Card {...props}>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <div className="card-text">
          {props.children}
        </div>
        {props.footer}
      </Card.Body>
    </Card>
  )
}

export default CardCustom;
import React, { useState, useEffect } from 'react'
import { InputCustom } from '../input'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsFillGearFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const WAIT_INTERVAL = 1200
let timer = null

const InputSearch = ({ onChange, ...props }) => {
  const [textSearch, setTextSearch] = useState('')

  useEffect(() => {
    const triggerChange = () => {
      onChange(textSearch)
    }

    if (textSearch.length > 1) {
      clearTimeout(timer)
      timer = setTimeout(triggerChange, WAIT_INTERVAL)
    }
  }, [onChange, textSearch])

  return (
    <>
      <AiOutlineSearch className="icon-input" />
      <InputCustom
        type="text"
        icon={1}
        placeholder="Introduzca el título, autor, ISBN..."
        onChange={(e) => setTextSearch(e.target.value)}
        {...props}
      />
      <Link to={'/buscador-avanzado'}>
        <BsFillGearFill className="icon-input-gear" />
      </Link>
    </>
  )
}

export default InputSearch

import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button, Alert, Form } from 'react-bootstrap'
import { useTrackVisibility } from 'react-intersection-observer-hook'

//own components
import { InputCustom, SelectInputCustom } from '../../components/input'
import ItemList from '../../components/itemList/item'

//api
import { listEncuadernacion, listIdiomas, listPaises } from '../../api/libros'
import { listAudiencia, searchFilterProduct } from '../../api/products'

//css
import './searchadvanced.scss'

const SearchAdvanced = () => {
  const [isLoading, setisLoading] = useState(false)
  const [loadNextPage, setLoadNextPage] = useState(false)
  const [countItems, setcountItems] = useState(0)
  const [page, setPage] = useState(0) // page displayed
  const [query, setquery] = useState({
    ean: '',
    titulo: '',
    texto_resumen: '',
    fecha_public: '',
    num_edicion: '',
    autor: '',
    coleccion: '',
    num_en_coleccion: '',
    ilustrador: '',
    editorial: '',
    materia_cdu: '',
    encuadernacion: '',
    pais_public: '',
    idioma_orig: '',
    clasificacion: '',
    type: 'libro',
  })
  const [encuadernacion, setEncuadernacion] = useState([])
  const [idiomas, setIdiomas] = useState([])
  const [paises, setPaises] = useState([])
  const [audiencia, setAudiencia] = useState([])
  const [products, setproducts] = useState([])

  const defaultChoice = (() => ({ id: 0, value: '' }))()

  const [ref, { isVisible }] = useTrackVisibility()

  const clearQuery = () => {
    let queryClear = { ...query }
    Object.keys(queryClear).map((val, i) => (queryClear[val] = ''))
    return queryClear
  }

  const nf = new Intl.NumberFormat('de-DE')

  const onSearchAdvanced = async () => {
    setisLoading(true)
    let payload = {
      ...query,
      encuadernacion: query.encuadernacion?.value,
    }
    const { data } = await searchFilterProduct(payload)
    if (data) {
      const { next, count, results } = data
      setLoadNextPage(() => next !== null)
      // setcountItems(count)
      setcountItems(nf.format(count))
      setproducts(results)
      setisLoading(false)
      // setquery(clearQuery())
      if (results.length) setPage(1)
    }
  }

  const submitSearch = (e) => {
    e.preventDefault()
    if (!isLoading) onSearchAdvanced()
  }

  //efects
  useEffect(() => {
    const getEncuadernacion = async () => {
      let { data } = await listEncuadernacion()
      if (data) {
        data = data.map((val, i) => {
          return { id: val.id, value: val['descripcion'] }
        })
        data = [defaultChoice, ...data]
        setEncuadernacion(data)
      }
    }
    const getIdiomas = async () => {
      let { data } = await listIdiomas()
      if (data) {
        data = data.map((val, i) => {
          return { id: val.id, value: val['codigo'] + ' ' + val['nombre'] }
        })
        data = [defaultChoice, ...data]
        setIdiomas(data)
      }
    }
    const getPaises = async () => {
      let { data } = await listPaises()
      if (data) {
        data = data.map((val, i) => {
          return { id: val.id, value: val['codigo'] + ' ' + val['nombre'] }
        })
        data = [defaultChoice, ...data]
        setPaises(data)
      }
    }
    const getAudiencia = async () => {
      let { data } = await listAudiencia()
      if (data) {
        data = data.map((val, i) => {
          return { id: val.id, value: val['descripcion'] }
        })
        data = [defaultChoice, ...data]
        setAudiencia(data)
      }
    }
    getEncuadernacion()
    getIdiomas()
    getPaises()
    getAudiencia()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const PaginationProducts = async () => {
      if (!loadNextPage) return
      const queryPag = { page, ...query }
      queryPag.page = queryPag.page + 1 // next page
      const { data } = await searchFilterProduct(queryPag)
      if (data) {
        const { next, results } = data
        setLoadNextPage(() => next !== null)
        setproducts([...products, ...results])
        setisLoading(false)
      }
    }

    if (isVisible && products.length) {
      PaginationProducts()
      setPage((prevPage) => prevPage + 1) // update value of last page displayed
    }

    // eslint-disable-next-line
  }, [isVisible])

  return (
    <Container>
      <Row
        xs={2}
        md={4}
        lg={12}
        className="title-search justify-content-center"
      >
        <Col lg={6}>
          <h2>{'Buscador avanzado'}</h2>
        </Col>
      </Row>
      <form onSubmit={submitSearch}>
        <Card>
          <Row style={{ margin: 30 }}>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="EAN"
                value={query.ean}
                onChange={(e) => setquery({ ...query, ean: e.target.value })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Título"
                value={query.titulo}
                onChange={(e) => setquery({ ...query, titulo: e.target.value })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Descripción"
                value={query.texto_resumen}
                onChange={(e) =>
                  setquery({ ...query, texto_resumen: e.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Año de publicación"
                value={query.fecha_public}
                onChange={(e) =>
                  setquery({ ...query, fecha_public: e.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Num de edición"
                value={query.num_edicion}
                onChange={(e) =>
                  setquery({ ...query, num_edicion: e.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Autor"
                value={query.autor}
                onChange={(e) => setquery({ ...query, autor: e.target.value })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Colección"
                value={query.coleccion}
                onChange={(e) =>
                  setquery({ ...query, coleccion: e.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="N° Colección"
                value={query.num_en_coleccion}
                onChange={(e) =>
                  setquery({ ...query, num_en_coleccion: e.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type="text"
                placeholder="Ilustrador"
                value={query.ilustrador}
                onChange={(e) =>
                  setquery({ ...query, ilustrador: e.target.value })
                }
              />
            </Col>
            <Col md={6}>
              <InputCustom
                type="text"
                placeholder="Editorial"
                value={query.editorial}
                onChange={(e) =>
                  setquery({ ...query, editorial: e.target.value })
                }
              />
            </Col>
            <Col md={6}>
              <InputCustom
                type="text"
                placeholder="Materia/CDU"
                value={query.materia_cdu}
                onChange={(e) =>
                  setquery({ ...query, materia_cdu: e.target.value })
                }
              />
            </Col>
            <Col md={3}>
              <SelectInputCustom
                choices={encuadernacion}
                label={'Encuadernacion'}
                onCheckedItem={(event) => {
                  let value = encuadernacion.filter((el) => {
                    return parseInt(event) === el.id
                  })
                  setquery({ ...query, encuadernacion: value[0] })
                }}
                value={query.encuadernacion.id}
              />
            </Col>
            <Col md={3}>
              <SelectInputCustom
                choices={paises}
                label={'País de publicación'}
                onCheckedItem={(val) =>
                  setquery({ ...query, pais_public: val })
                }
                value={query.pais_public}
              />
            </Col>
            <Col md={3}>
              <SelectInputCustom
                choices={idiomas}
                label={'Idioma'}
                onCheckedItem={(val) =>
                  setquery({ ...query, idioma_orig: val })
                }
                value={query.idioma_orig}
              />
            </Col>
            <Col md={3}>
              <SelectInputCustom
                choices={audiencia}
                label={'Audiencia'}
                onCheckedItem={(val) =>
                  setquery({ ...query, clasificacion: val })
                }
                value={query.clasificacion}
              />
            </Col>
            <Col md={3}>
              <Form.Check type="checkbox" id="Stock" label="Stock disponible" />
            </Col>
            <Col md={12}>
              <Row className="justify-content-end">
                <Col lg={3}>
                  <Button
                    className="button-search"
                    variant="primary"
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? 'Cargando...' : 'Buscar'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </form>
      <Row className="mt-5">
        <Col lg={12} sm={12} xs={12}>
          {products.length > 0 ? (
            <Alert variant={'primary'}>
              Se encontraron {countItems} resultados
            </Alert>
          ) : null}
        </Col>
        {products.map((item, i) => (
          <Col md={4} lg={3} sm={6} key={i} style={{ marginTop: 5 }}>
            <ItemList key={i} data={item} />
          </Col>
        ))}
      </Row>
      <div aria-hidden="true" ref={ref}></div>
    </Container>
  )
}
export default SearchAdvanced

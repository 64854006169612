import { methodPut, methodGet, methodPost } from './methods'

import { API_INFO } from '../constans'

export const changePassword = async (params) => {
  return methodPut(API_INFO + 'user/actions/change-pass/', params)
}

export const saveChanges = async (params) => {
  return methodPut(API_INFO + 'user/actions/save-changes/', params)
}

export const infoUser = async () => {
  return methodGet(API_INFO + 'user/actions/info/', {})
}

export const registerUser = async (params) => {
  return methodPost(API_INFO + 'user/users/', params)
}

export const recoverPassword = async (params) => {
  return methodPost(API_INFO + 'user/users/recover-password/', params)
}

export const getMyOrders = async () => {
  return methodGet(API_INFO + 'storehouse/orders/get_orders_by_user/')
}

export const changeRecoverPassword = async (params) => {
  return methodPost(API_INFO + 'user/users/set-password/', params)
}

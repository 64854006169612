import React, { useState, useCallback, useEffect } from 'react'
import { quickSearch } from '../../api/searchs'
import { debounce } from 'lodash'

// own components
import InputSearch from './InputSearch'
import SearchResult from './SearchResult'

const SearchBar = () => {
  const [data, setData] = useState([])
  const [isSearching, setIsSearching] = useState(null)
  const [hasError, setHasError] = useState(false)
  let path = window.location.pathname

  const searchRequest = useCallback(async (value) => {
    setData(null)
    setIsSearching(true)
    try {
      const { data } = await quickSearch({ value })
      setData(data.results)
    } catch (e) {
      setHasError(true)
      setData(null)
    }
  }, [])

  const handleBlur = (e) => {
    if (e.currentTarget.id === 'searchContainer') {
      setIsSearching(true)
    }
    if (e.currentTarget.id === 'closeSearchContainer') {
      setIsSearching(false)
    }
  }

  const hiddenContainer = () => {
    setIsSearching(false)
  }

  const onChange = useCallback(
    debounce(async (value) => {
      if (value) {
        await searchRequest(value)
      }
    }, 200),
    [searchRequest]
  )

  const handleFocus = useCallback(
    async (value) => {
      const inputValue = value.target.value
      if (inputValue.length > 1) {
        await searchRequest(inputValue)
      }
    },
    [searchRequest]
  )

  useEffect(() => {
    if (path === '/buscador-avanzado') {
      setIsSearching(false)
    }
  }, [path])

  return (
    <form role="search">
      <InputSearch
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.preventDefault()
          }
        }}
        onChange={onChange}
        onFocus={handleFocus}
      />
      {isSearching === true ? (
        <SearchResult
          results={data}
          handleBlur={handleBlur}
          onBlur={hiddenContainer}
        />
      ) : null}
    </form>
  )
}

export default SearchBar

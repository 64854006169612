import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import Image from 'react-bootstrap/Image'

const ItemSlider = (props) => {
  return (
    <>
      <div className="itemCarrousel">
        <div className="containerFileItem">
          {props.item.type_rsc === 1 ? (
            props.item.resource.indexOf('youtube') ? (
              <ReactPlayer
                width="80%"
                height="100%"
                controls={true}
                playing={props.isPlaying}
                id={props.i + props.item.resource}
                url={props.item.resource}
              />
            ) : (
              <video
                src={props.item.resource}
                controls
                muted
                autoPlay
                id={props.i + props.item.resource}
                width="100%"
                preload="auto"
                loop
              >
                {/* <source src={item.resource} type="video/mp4" /> */}
              </video>
            )
          ) : (
            <img
              src={props.item.resource}
              alt={props.item.title}
              width="100%"
            />
          )}
        </div>
        {props.item.description ? (
          <div className="containerTextItem">
            <div
              className="textItem"
              dangerouslySetInnerHTML={{ __html: props.item.description }}
            ></div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ItemSlider

import { methodGet } from './methods';
import { API_INFO } from '../constans';

export async function quickSearch(value){
  const url = API_INFO + "products/search"
  return methodGet(url, value)
}

export async function newQuickSearch(value){
  const url = API_INFO + "products/base-search"
  return methodGet(url, value)
}

export async function advancedSearch(query){
  const url = API_INFO + "products/advanced-search"
  return methodGet(url, query)
}
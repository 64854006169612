import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { loginSuccess } from '../../slices/user'
import { companySelector } from '../../slices/company'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Button, Alert } from 'react-bootstrap'
import { emailRegex } from '../../constans'
// own components
import Modal from '../../components/modal'
import { InputCustom } from '../../components/input/index'
import {
  cartSelector,
  addItem,
  setBillingAddress,
  setShippingAddress,
} from '../../slices/cart'
//api
import { loginApi } from '../../api/login'
import { registerUser, recoverPassword } from '../../api/users'
import './login.scss'
import { getCart, addItemCart } from '../../api/ecommerce'
import { Link } from 'react-router-dom'

const LoginModal = (props) => {
  const [isRegister, setIsRegister] = useState(false)
  const [acceptConditions, setAcceptConditions] = useState(true)
  const [isRecover, setIsRecover] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [feedback, setFeedback] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [successSendEmail, setSuccessSendEmail] = useState(false)
  const [showCriteriaAdvice, setShowCriteriaAdvice] = useState(false)
  const [isCapsLockOn, setIsCapsLockOn] = useState(false)

  const checkCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true)
    } else {
      setIsCapsLockOn(false)
    }
  }

  let url = window.location.host
  let acronimo

  useEffect(() => {
    if (url === 'criteriaclub.infobibliotecas.com') {
      setShowCriteriaAdvice(true)
    } else {
      setShowCriteriaAdvice(false)
    }
  }, [url])

  useEffect(() => {
    if (isRegister) {
      setAcceptConditions(false)
    } else {
      setAcceptConditions(true)
    }
  }, [isRegister])

  const { items } = useSelector(cartSelector)
  const { name } = useSelector(companySelector)

  const urlLegals = '/aviso-legal/'
  const urlConditions = '/condiciones/'
  const strTerms = (
    <p>
      He leído y acepto las <Link to={urlLegals}>políticas legales</Link> y las{' '}
      <Link to={urlConditions}>condiciones de servicio</Link> de {name}.
    </p>
  )

  const dispatch = useDispatch()

  const headerLogin = () => (
    <div className="headerText">
      {isRecover
        ? 'Recuperar contraseña'
        : isRegister
        ? 'Registro'
        : 'Iniciar Sesión'}
    </div>
  )

  const cleanForm = () => {
    setFeedback('')
    setSuccessSendEmail(false)
    setUsername('')
    setEmail('')
    setPassword('')
    setRepeatPassword('')
  }

  const onLogin = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setSuccessSendEmail(false)
      setIsLoading(true)
      setFeedback('')
      const form = event.target
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      }
      const payload = { username, password }
      const { status, data } = await loginApi(payload)
      if (status === 200) {
        localStorage.setItem('access', data.access)
        localStorage.setItem('refresh', data.refresh)
        localStorage.setItem('type_user', data.type_user)
        localStorage.setItem('permissions', data.permissions)
        dispatch(loginSuccess())
        dispatch(setBillingAddress(data.direccion_facturacion))
        dispatch(setShippingAddress(data.direccion_envio))
        const cart = await getCart()
        if (cart.status === 200) {
          if (cart.data.items) {
            for (const item of cart.data.items) {
              dispatch(addItem(item.producto))
            }
          }
        } else {
          console.log('error')
        }
        for (const item of items) {
          await addItemCart({ item: item.id, quantity: item.quantity })
        }

        setIsLoading(false)
        props.handleClose()
      } else {
        const errfeedback = Object.keys(data)
          .map((val, i) => {
            if (val.includes('non_field_errors'))
              return 'Credenciales invalidas'
            return `${val} - ${data[val]}`
          })
          .join('-')
        setFeedback(errfeedback)
        setIsLoading(false)
      }
    }
  }

  const onRegister = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setSuccessSendEmail(false)
      setIsLoading(true)
      setFeedback('')
      const form = event.target
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      }
      const emailValid = emailRegex.test(email)

      if (password.length > 0 && password === repeatPassword && emailValid) {
        const payload = { username, password, email }
        const { status, data } = await registerUser(payload)
        if (status === 201) {
          localStorage.setItem('access', data.access)
          localStorage.setItem('refresh', data.refresh)
          dispatch(loginSuccess())
          dispatch(setBillingAddress(data.direccion_facturacion))
          dispatch(setShippingAddress(data.direccion_envio))
          for (const item of items) {
            await addItemCart({ item: item.id, quantity: item.quantity })
          }
          setIsLoading(false)
          props.handleClose()
        } else {
          if (status === 500) {
            const errfeedback = 'Error interno intentar mas tarde'
            setFeedback(errfeedback)
            setIsLoading(false)
          } else {
            const errfeedback = Object.keys(data)
              .map((val, i) => {
                if (val.includes('non_field_errors'))
                  return 'Credenciales invalidas'
                return `${val} - ${data[val]}`
              })
              .join('-')
            setFeedback(errfeedback)
            setIsLoading(false)
          }
        }
      } else if (password.length === 0) {
        setFeedback('Debes de ingresar una contraseña')
        setIsLoading(false)
      } else if (emailValid === false) {
        setFeedback('Email inválido.')
        setIsLoading(false)
      } else {
        setFeedback('La contraseña de confirmación no coincide')
        setIsLoading(false)
      }
    }
  }

  const onRecover = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setSuccessSendEmail(false)
      setIsLoading(true)
      setFeedback('')

      const form = event.target
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      }
      if (email.length > 0) {
        const payload = { email }
        const { status, data } = await recoverPassword(payload)
        if (status === 200) {
          setSuccessSendEmail(true)
          setIsLoading(false)
        } else if (status === 404) {
          setFeedback('El correo no existe o es incorrecto')
          setIsLoading(false)
        } else {
          setFeedback('Problemas de conexión, inténtelo más tarde')
          setIsLoading(false)
        }
      } else {
        setFeedback('Debes de ingresar un correo')
        setIsLoading(false)
      }
    }
  }

  const loginForm = () => (
    <div onKeyDown={onLogin}>
      <Form onSubmit={onLogin}>
        <InputCustom
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label="Usuario"
          placeholder={'ingrese su email'}
        />
        <InputCustom
          onKeyUp={checkCapsLock}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Contraseña"
          type="password"
          placeholder={'ingrese su contraseña'}
        />
        {isCapsLockOn ? (
          <Alert variant="danger"> Bloq Mayus Activado </Alert>
        ) : null}
      </Form>
    </div>
  )

  const registerForm = () => (
    <div onKeyDown={onRegister}>
      <Form onSubmit={onRegister}>
        <InputCustom
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
            setUsername(e.target.value)
          }}
          label="Correo electrónico"
          type="email"
          placeholder={'Ingrese su correo'}
        />
        <InputCustom
          onKeyUp={checkCapsLock}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Contraseña"
          type="password"
          placeholder={'Ingrese su contraseña'}
        />
        <InputCustom
          onKeyUp={checkCapsLock}
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          label="Repetir contraseña"
          type="password"
          placeholder={'Repetir contraseña'}
        />
        {isCapsLockOn ? (
          <Alert variant="danger"> Bloq Mayus Activado </Alert>
        ) : null}
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            onChange={(e) => setAcceptConditions(!acceptConditions)}
            type="checkbox"
            label={strTerms}
          />
        </Form.Group>
      </Form>
    </div>
  )

  const recoverForm = () => (
    <div onKeyDown={onRecover}>
      <Form onSubmit={onRecover}>
        <InputCustom
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Ingrese su correo electrónico"
          type="email"
          placeholder={'Ingrese su correo'}
        />
      </Form>
    </div>
  )

  const BodyLogin = () =>
    isRecover ? recoverForm() : !isRegister ? loginForm() : registerForm()

  useEffect(() => {
    let assign = props.register ? setIsRegister(true) : setIsRegister(false)
  }, [])

  const FooterLogin = () => (
    <div className="footerLogin">
      <span className="feedback">{feedback}</span>
      {successSendEmail ? (
        <Row className="alert-success">
          <span>Se ha enviado un enlace a su correo</span>
        </Row>
      ) : null}
      {acceptConditions && (
        <Button
          variant="primary"
          disabled={isLoading}
          onClick={
            isLoading
              ? null
              : isRecover
              ? onRecover
              : isRegister
              ? onRegister
              : onLogin
          }
        >
          {isLoading
            ? 'Cargando...'
            : isRecover
            ? 'Recuperar contraseña'
            : isRegister
            ? 'Registrarme'
            : 'Entrar'}
        </Button>
      )}
      {showCriteriaAdvice && (
        <span
          style={{ color: '#636362', fontSize: '13px' }}
          className="mt-3 pt-2 mb-0"
        >
          Estimado usuario de{' '}
          <strong style={{ color: '#a11515' }}>Criteria Club</strong>, queremos
          informarle que hemos actualizado nuestra base de datos, por lo que
          deberá recuperar su contraseña en caso de esta figure deshabilitada.
          Disculpe las molestias ocasionadas.
        </span>
      )}
      <br />

      <div className="footerOptions">
        {!isRecover ? (
          <div className="boxText">
            <div className="questionText">Recuperar contraseña</div>{' '}
            <div
              className="linkText"
              onClick={() => {
                setIsRegister(setIsRecover(true))
                cleanForm()
              }}
            >
              Te ayudamos
            </div>
          </div>
        ) : null}

        {isRecover || isRegister ? (
          <div className="boxText">
            <div className="questionText">¿Ya tienes cuenta?</div>{' '}
            <div
              className="linkText"
              onClick={() => {
                setIsRecover(setIsRegister(false))
                cleanForm()
              }}
            >
              Entrar
            </div>
          </div>
        ) : null}

        {!isRegister ? (
          <div className="boxText">
            <div className="questionText">¿No tienes cuenta?</div>{' '}
            <div
              className="linkText"
              onClick={() => {
                setIsRecover(setIsRegister(true))
                cleanForm()
              }}
            >
              Regístrate
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )

  return (
    <Modal
      show={props.show}
      onHide={() => props.handleClose()}
      title={headerLogin()}
      children={BodyLogin()}
      footer={FooterLogin()}
    ></Modal>
  )
}

export default LoginModal

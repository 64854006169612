import React, { useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { companySelector } from '../../slices/company.js'

const Conditions = () => {
  const { name, direccion_completa, telefono, email, data } = useSelector(
    companySelector
  )
  let url = window.location.host
  let acronimo

  if (url === 'ochoymediolibrosdecine.es') {
    acronimo = 'OYM'
  } else {
    acronimo = 'Librería'
  }

  return (
    <Container>
      <Row>
        <Col className="title" md={12}>
          <h2>Condiciones de venta</h2>
          <span className="divider-title"></span>
          <br />
          <br />
          <div className="legal">
            <h3>PREÁMBULO</h3>
            <p>
              En los presentes términos y condiciones de venta, "{acronimo}" y
              "nosotros" significan "{name}” y "Ud." significará Ud. el cliente.
            </p>
            <p>
              Estas condiciones generales de venta (en adelante las "condiciones
              de venta"), regulan la relación jurídica que emana de los procesos
              de compra y venta realizados por Ud. en la página web ubicada en
              la URL {url} y el propietario de la misma, {name},
              {data.nif ? ` con NIF: ${data.nif},` : ''}
              &nbsp;domicilio social en Calle {direccion_completa},. Ud. acepta
              las condiciones de venta desde el instante que utilice o contrate
              el servicio o adquiera cualquier producto. Las condiciones de
              venta estarán disponibles en la página web en todo momento, sin
              necesidad de realizar un pedido para acceder a ellas. Este
              documento puede ser impreso y almacenado por Ud. {acronimo} puede
              modificar las condiciones; el cliente aceptará las condiciones
              vigentes en el momento de realizar cada compra. Las modificaciones
              son aplicables a partir de su publicación en internet y no podrán
              aplicarse a las operaciones concluidas anteriormente.
            </p>
            <p>
              {acronimo} pone a su disposición la dirección de e-mail {email},
              para que pueda plantear cualquier duda acerca de las condiciones
              de venta.
            </p>
            <br />
            <h3>1. REALIZACIÓN DE SU PEDIDO</h3>
            <p>
              1.1. Para poder realizar un pedido deberá Ud. tener 18 años y
              capacidad para contratar.
            </p>
            <p>
              1.2. Ud. puede realizar el pedido a través de nuestra tienda
              on-line en {url}, por correo electrónico a la dirección {email}, o
              llamando al teléfono {telefono}. No se aceptarán pedidos que
              lleguen de forma distinta a las anteriormente indicadas.
            </p>
            <p>
              1.3. Para realizar un pedido en {url}, es necesario registrarse
              como usuario rellenando el correspondiente formulario electrónico,
              antes de iniciar el proceso de compra. Una vez registrado, se
              puede proseguir con el proceso de compra del producto o productos
              añadidos a la cesta o navegar la web para añadir nuevos productos
              en ella.
            </p>
            <p>
              1.4. Al realizar su pedido Usted nos está haciendo una oferta de
              compra de los productos que haya seleccionado de acuerdo con estos
              términos y condiciones de venta.
            </p>
            <p>
              1.5. Una vez aceptado su pedido, le enviaremos una confirmación
              por correo electrónico donde constará también el número de pedido.
              La confirmación de pedido será efectiva desde su envío. Si no
              podemos tramitar su pedido por alguna incidencia, nos pondremos en
              contacto con Usted por correo electrónico o teléfono.
            </p>
            <p>
              1.6. Una vez registrado y teniendo todos los productos requeridos
              en la cesta, se prosigue la compra llegando a la pantalla de
              selección de Formas de Pago, Métodos de Envío y selección de los
              datos de facturación y/o envío. Las Formas de Pago aceptadas, sus
              condiciones y los métodos de envío son los especificados en los
              siguientes puntos de estas condiciones.
            </p>
            <p>
              1.7. Una vez realizada la compra, {acronimo} enviará a los
              clientes un email con el recibo del pedido que contendrá el
              resumen de la compra, así como información de pago si procede y
              datos de envío y facturación.
            </p>
            <p>
              1.8. {acronimo} enviará una factura en soporte físico que se
              recibirá junto con el pedido. Esta factura se emitirá a nombre de
              la persona física o jurídica que haya realizado el pedido, por lo
              que los clientes deberán asegurarse de realizarlo con el nombre
              correcto. También podrá consultar o descargar sus facturas en
              formato PDF desde su área de cliente dentro de la página web en el
              apartado de pedidos. El producto o productos se enviarán según el
              Método de Envío seleccionado por el cliente.
            </p>
            <br />
            <h3>2. PRECIOS Y GASTOS DE ENVÍO</h3>
            <p>
              2.1. Los precios aplicables a cada producto y/o servicio son los
              indicados en la página web en la fecha del pedido incluyendo, en
              su caso, todos ellos el IVA (Impuesto sobre el Valor Añadido). No
              obstante, atendiendo a lo establecido en la Ley 37/1992, de 28 de
              diciembre, reguladora de dicho impuesto, la operación puede
              resultar exenta o no sujeta al mismo en función del país de
              residencia del comprador y de la condición en la que actúa el
              mismo (empresario/profesional o particular). En consecuencia, en
              algunos supuestos el precio final del pedido puede verse alterado
              respecto al que figura expuesto en la página web.
            </p>
            <p>
              2.2. En los envíos a Islas Canarias, Ceuta y Melilla, el cliente,
              al recibir el producto, correrá con los gastos de despacho de
              aduanas, así como el pago del impuesto local, IGIC o similares.
            </p>
            <p>
              2.3. En los envíos realizados a países no pertenecientes a la
              Unión Europea, el cliente asumirá los gastos correspondientes a
              impuestos y aranceles aduaneros propios de cada país
            </p>
            <p>
              2.4. Los envíos a España y Portugal peninsulares son gratuitos
              para el cliente. Los envíos a las regiones de Islas Canarias,
              Baleares, Ceuta y Melilla serán gratuitos si el pedido es superior
              a 100 €, y en caso de ser menor tendrá un coste en proporción al
              valor del pedido que se mostrará en el apartado de gastos de envío
              del carro de la compra.
            </p>
            <p>
              2.5. Los gastos de envío para el resto de países correrán a cargo
              del cliente, y dependerán del peso y del lugar de destino;
              automáticamente le mostrará la cotización en la casilla de gastos
              de envío del carro de la compra una vez rellenados los datos de
              destino.
            </p>
            <br />
            <h3>3. FORMAS DE PAGO</h3>
            <p>
              3.1. Usted puede pagar por los siguientes medios:
              <li>Tarjeta de crédito/debito VISA, MASTERCARD, AMEX</li>
              <li>PayPal</li>
              <li>Contra Reembolso (Solamente España)</li>
            </p>
            <p>
              3.2. Si paga Ud. con tarjeta de crédito, deberá proporcionar los
              datos de su tarjeta en el momento de realizar su pedido. Sólo
              aceptamos pagos con tarjetas seguras en los que se efectúa
              autentificación de su identidad como titular de la tarjeta según
              el método establecido por su banco, a través del servicio de
              comercio electrónico seguro.
            </p>
            <p>
              3.3. Si elige usted el pago contra reembolso, deberá pagar el
              total de la factura a la entrega de la mercancía, más un recargo
              de 3€ por gastos de gestión. Si al hacer el pedido elige dos
              entregas, deberá pagar 3€ de gastos de gestión por cada entrega,
              pero se cobrará el total de los gastos en la primera entrega, es
              decir 6 € en la primera entrega y ningún gasto en la segunda
              entrega.
            </p>
            <p>
              3.4. El importe máximo aceptado para pagos en efectivo en Tienda
              será de 2.000€.
            </p>
            <br />
            <h3>4. ENTREGA DE SU PEDIDO</h3>
            <p>
              4.1. Le enviaremos su pedido a la dirección de envío que figure en
              su solicitud de pedido.
            </p>
            <p>
              4.2. Enviaremos su pedido dentro del plazo de envío que figure en
              nuestra web en la fecha de emisión de su confirmación de pedido.
            </p>
            <p>
              4.3. Podrá elegir, al cursar el pedido, recibirlo en un solo
              envío, o bien en un primer envío los productos que tengamos en
              stock, y en un segundo envío el resto de productos. La opción de
              dos envíos es solamente válida para España, y no se ofrecerá para
              el resto de países.
            </p>
            <p>
              4.4. Usted puede cancelar su pedido en cualquier momento antes del
              envío del mismo enviando un email a{email} indicando su número de
              pedido.
            </p>
            <p>
              4.5. En el momento en que nuestro transportista se haga cargo de
              su pedido, le enviaremos confirmación del envío por un SMS al
              teléfono móvil que Ud. haya facilitado al realizar el pedido.
            </p>
            <p>
              4.6. Las entregas se realizan en el plazo de dos (2) a tres (3)
              días hábiles en caso de envíos estándar por Correos y recogida en
              oficina de Correos para Península y Baleares, y de siete (7) a
              diez (10) días hábiles para Islas Canarias, Ceuta o Melilla. Será
              de veinticuatro (24) horas en el caso de envíos por mensajería
              para Península y de veinticuatro (24) a cuarenta y ocho (48) horas
              para Baleares y Portugal.
            </p>
            <p>
              4.7. La titularidad y el riesgo de pérdida de los productos del
              pedido le serán traspasados al serle entregado el pedido.
            </p>
            <p>
              4.8. En el caso de envíos a Islas Canarias, Ceuta, Melilla y
              países no miembros de la UE, los gastos de despacho de aduanas,
              así como el pago del impuesto local, correrán siempre a cargo del
              cliente. En el supuesto que el cliente rechace el pedido en el
              momento de la entrega y éste sea devuelto a {acronimo} por la
              empresa de transporte, se descontarán del reembolso de la compra
              los gastos de aduanas, impuesto local y gastos de envío
              ocasionados, si los hubiese.
            </p>
            <p>
              4.9. Habrá que tener en cuenta que los plazos expuestos en el
              punto 4.6 aplicarán a los productos señalados como “En stock” en
              la tienda online. Para el resto de productos habrá que sumar los
              plazos de expedición notificados convenientemente en cada ficha de
              producto: “Recíbelo en un plazo de 3-4 días”, “Recíbelo en un
              plazo de 7-10 días” , “Recíbelo en un plazo de 2-3 semanas”,
              “Recíbelo en un plazo de 3-6 semanas”, “Recíbelo en un plazo de 6
              -12 semanas”.
            </p>
            <p>
              4.10. El horario de recepción de pedidos es de veinticuatro (24)
              horas los trescientos sesenta y cinco (365) días del año, si bien
              no se procesarán pedidos realizados después de las diecisiete (17)
              horas, quedando para el día hábil siguiente en la ciudad de
              Madrid. Los pedidos recibidos durante los sábados y domingos serán
              procesados a primera hora del lunes siguiente, o primer día hábil.
              Los pedidos recibidos durante días festivos nacionales y los
              locales de la comunidad de Madrid se procesarán el primer día
              siguiente hábil.
            </p>
            <br />
            <h3>5. SATISFACCIÓN O DEVOLUCIÓN. DERECHO DE DESISTIMIENTO</h3>
            <p>
              5.1. Usted dispondrá de un plazo de 14 días naturales desde la
              recepción del bien para resolver el contrato.
            </p>
            <p>
              5.2. El pedido devuelto deberá ser entregado junto con la factura,
              corriendo usted con los gastos de devolución. Usted podrá devolver
              cualquier artículo que nos haya comprado, siempre y cuando los
              productos conserven su precinto o embalaje original y los
              manuales, accesorios o regalos promocionales incluidos en su caso,
              de lo contrario el bien sufrirá una depreciación. El cliente sólo
              será responsable de la disminución de valor de los bienes
              resultante de una manipulación de los mismos distinta a la
              necesaria para establecer su naturaleza, sus características o su
              funcionamiento.
            </p>
            <p>
              5.3. Si un producto distinto al solicitado por usted fuera
              entregado por error nuestro, se le entregará el producto correcto,
              recogiendo el primero, sin ningún cargo adicional para usted.
            </p>
            <p>
              5.4. Si usted recibe un producto roto, dañado o en malas
              condiciones, nos haremos cargo de recogerlo en su domicilio,
              sustituyéndolo por otro en buen estado, sin cargos adicionales.
            </p>
            <p>
              5.5. No podrá devolvernos un producto y reclamarnos su reembolso
              si se trata de productos personalizados. No se podrán devolver
              productos si se trata de programas de ordenador (software) que
              Usted haya desprecintado, o libros que contengan acceso online con
              un código oculto que se haya utilizado; tampoco productos
              sensibles a la salud o higiene que hayan sido desprecintados.
            </p>
            <p>
              5.6. Para cualquier reclamación o consulta puede ponerse en
              contacto con nosotros a través del correo electrónico
              {email}.
            </p>
            <p>
              5.7. Para proceder a la devolución de cualquier producto se deberá
              cumplimentar el formulario que aparecerá pinchando el siguiente
              enlace de DEVOLUCIONES. El formulario es un formato de correo
              electrónico que, una vez relleno con los datos solicitados, se
              enviará a la dirección {email}, y recibirá contestación indicando
              si procede o no la devolución y los pasos a seguir para
              realizarla.
            </p>
            <p>
              5.8. Siempre que los productos nos sean devueltos sin daños, con
              la copia de la factura y el número de autorización que le habremos
              facilitado, le reembolsaremos el importe pagado por los productos
              que nos devuelva. Los gastos de envío de la devolución correrán a
              cargo del cliente. Si Ud. decide utilizar los servicios de
              logística inversa de {acronimo}, le enviaremos las instrucciones y
              los costes se deducirán del reembolso del importe del pedido. Si
              Ud. devuelve el producto por sus propios medios, nosotros nos
              haremos responsables del estado del producto a partir del momento
              en que lo recibamos en nuestro almacén. En este caso se
              reembolsará el importe del valor de los productos devueltos.
            </p>
            <p>
              5.9. En el caso de devoluciones desde las Islas Canarias, Ceuta,
              Melilla y países distintos de España, además de los gastos de
              envío de la devolución, también los gastos de despacho de aduanas
              e impuestos locales, si los hubiese, correrán a cargo del cliente.
            </p>
            <p>
              5.10. Sin afectar al derecho de desistimiento de los consumidores
              ni a la responsabilidad de la garantía por productos defectuosos,
              nuestra responsabilidad civil por los productos suministrados
              quedaría limitada al importe de los mismos.
            </p>
            <p>
              5.11. En caso de devolución, el reembolso de los importes pagados
              se efectuará por medio de transferencia bancaria.
            </p>
            <p>
              5.12. {acronimo} no devolverá el importe o realizará ningún
              reenvío de mercancía mientras no se haya verificado la recepción y
              estado de embalaje y accesorios del producto objeto de la
              devolución o cambio en nuestro almacén.
            </p>
            <p>
              5.13. Si al recibir el paquete, Ud. apreciase que la caja ha
              llegado defectuosa o cree que ha podido ser manipulada, Ud. no
              debe aceptar el paquete y debe contactarnos de inmediato. Si Ud.
              lo ha aceptado y el paquete ha sido dañado o manipulado debe
              contactarnos antes de 48h para poder tramitar la gestión con la
              compañía de transporte, mediante correo a la dirección {email} o
              llamada al número de teléfono: {telefono}, en horario de 9,00 a
              15,00 horas de lunes a viernes.
            </p>
            <br />
            <h3>6. GARANTÍA</h3>
            <p>
              6.1. Todos los productos que puede comprar en {name}.es tienen una
              garantía legal de dos (2) años a partir de la entrega. Cada
              fabricante tiene estipulado un periodo de garantía que puede ser
              en algunos casos superior a los 2 años.
            </p>
            <p>
              6.2. En caso de producto defectuoso, {acronimo} deberá proceder,
              según corresponda, a la reparación, sustitución, rebaja del precio
              o resolución del contrato, gestiones que serán gratuitas para Ud.,
              el consumidor y usuario. {acronimo}
              responde de las faltas de conformidad que se manifiesten en un
              plazo de dos (2) años desde la entrega al usuario o consumidor.
              Ud. deberá informar a {acronimo} de la falta de conformidad en el
              plazo de dos (2) meses desde que tuvo conocimiento de ella.
            </p>
            <p>
              6.3. Si tras realizar la revisión del producto, no se detectara
              ningún defecto o el problema no estuviese cubierto por la
              garantía, {acronimo} podrá reclamarle el importe de los gastos de
              envío de la tramitación, tanto de ida como de vuelta.
            </p>
            <p>
              6.4. Los costes de los envíos para la tramitación de la garantía
              quedan cubiertos dentro de los territorios España peninsular e
              insular y Portugal peninsular. Fuera de dichos territorios, será
              Ud. quien deberá hacerse cargo de dichos gastos de envío tanto de
              ida como de vuelta.
            </p>
            <br />
            <h3>
              7. CONTACTO CON {name.toUpperCase()} Y RECTIFICACIÓN DE PROBLEMAS
              Y ERRORES
            </h3>
            <p>Puede Ud. ponerse en contacto con nosotros a través de:</p>
            <li>
              Correo a {name} - {direccion_completa}.
            </li>
            <li>Por correo electrónico a {email}</li>
            <li>Por teléfono, llamando al número {telefono}</li>
            <p>
              Al ponerse en contacto con nosotros en relación con un pedido que
              haya realizado, deberá indicar su número de pedido.
            </p>
            <br />
            <h3>8. CONTENIDOS E INFORMACIÓN SUMINISTRADA EN NUESTRA WEB</h3>
            <p>
              8.1. {acronimo} se reserva el derecho a modificar la oferta
              comercial presentada en la web {url} (modificación sobre
              productos, precios, promociones y otras condiciones comerciales y
              de servicio) en cualquier momento.
            </p>
            <p>
              8.2. {acronimo} pone todos los medios razonables a su disposición
              para intentar que la información mostrada en su página de internet
              sea veraz y sin errores tipográficos. Si se produjese algún error
              de este tipo, ajeno en todo momento a la voluntad de {acronimo},
              se corregiría de inmediato, tan pronto como se detecte. Si el
              error se produce en algunos de los precios mostrados y algún
              cliente hubiese realizado una compra, le comunicaremos dicho error
              y el cliente o usuario tendrá derecho a anular su pedido sin coste
              por su parte. El envío de una confirmación automática de compra no
              valida las condiciones de un precio erróneo.
            </p>
            <p>
              8.3. {acronimo} extrae las características y descripciones de los
              productos, ofrecidos en la web, de las informaciones que los
              editores, fabricantes y distribuidores facilitan a {acronimo} y,
              en algunos casos, de sus propias webs. En el caso que la
              información sobre el producto no se corresponda con las
              características reales, el cliente podrá anular el pedido sin
              coste alguno por su parte.
            </p>
            <p>
              8.4. La información presentada en la web se encuentra en español,
              incluyendo la formalización del contrato y todas las informaciones
              contractuales mostradas y enviadas a los consumidores o usuarios.
            </p>
            <br />
            <h3>9. PROTECCIÓN DE DATOS</h3>
            <p>
              Al realizar un pedido el cliente aceptará el tratamiento, por
              parte de {acronimo}, de los datos personales por él
              proporcionados, mediante la aceptación expresa de la Política de
              Privacidad.
            </p>
            <br />
            <h3>10. FUERZA MAYOR</h3>
            <p>
              Nosotros haremos todo lo posible por cumplir con las obligaciones
              derivadas de su pedido. Sin embargo, no seremos responsables por
              demoras o incumplimiento si la demora o incumplimiento son debidos
              a fuerza mayor. En caso de demora, cumpliremos nuestras
              obligaciones tan pronto como nos sea razonablemente posible.
            </p>
            <br />
            <h3>11. LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE</h3>
            <p>
              Estas condiciones de venta se rigen por la ley española. Las
              partes se someten, a su elección, para la resolución de los
              conflictos y con renuncia a cualquier otro fuero, a los juzgados y
              tribunales de la comunidad de Madrid.
            </p>
            <br />
            <h3>12. NULIDAD E INEFICACIA DE LAS CLÁUSULAS</h3>
            <p>
              Si cualquier cláusula incluida en estas condiciones de venta fuese
              declarada, total o parcialmente, nula o ineficaz, tal nulidad o
              ineficacia afectará tan solo a dicha disposición o a la parte de
              la misma que resulte nula o ineficaz, subsistiendo las condiciones
              de venta en todo lo demás, teniéndose tal disposición, o la parte
              de la misma que resultase afectada, por no puesta.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Conditions

import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { getInfoRedsys } from '../../api/ecommerce'

const TPV = (props) => {
  const [redsys, setRedsys] = useState(null)

  const callGetInfoRedsys = async () => {
    const rs = await getInfoRedsys()
    if (rs.status === 200) {
      const { data } = rs
      setRedsys(data)      
    }
  }

  useEffect(() => {
    callGetInfoRedsys()
  }, [])
				

	return (
    <>
      {redsys ? (
      <Row>
        <Col md={4}>
          <div 
            dangerouslySetInnerHTML={{ __html: redsys }} 
            className="footerCardBlog"
          >
          </div>         
        </Col>        
      </Row>
      ) : (
        <Row>
        </Row>
      )}
    </>
  )
}

export default TPV
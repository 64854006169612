import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import ImageNotFound from '../../assets/img/image_not_found.png'
import preview from '../../assets/img/preview.jpg'
import Image from '../../components/image'
import {
  cartSelector,
  removeItem,
  increaseQuantity,
  decreaseQuantity,
} from '../../slices/cart'
import { useSelector, useDispatch } from 'react-redux'
import {
  AiFillDelete,
  AiFillPlusCircle,
  AiFillMinusCircle,
} from 'react-icons/ai'
import { COMPANY } from '../../constans'
import { userSelector } from '../../slices/user'
import {
  addItemCart,
  decreaseQuantityItemCart,
  removeItemCart,
} from '../../api/ecommerce'
import {
  StepperAction,
  StepperContent,
  StepperContext,
} from 'react-material-stepper'

import './cart.scss'

const Items = (props) => {
  const dispatch = useDispatch()
  const { isLogged } = useSelector(userSelector)
  const { items, shippingCosts } = useSelector(cartSelector)
  const { resolve } = React.useContext(StepperContext)

  useEffect(() => {
    window.document.title = 'Carrito de compras | ' + COMPANY
  }, [])

  const increaseQuantityItem = async (id) => {
    if (isLogged) {
      const payload = { item: id, quantity: 1 }
      const { status, dataResponse } = await addItemCart(payload)
      if (status === 201) {
        dispatch(increaseQuantity(id))
      } else {
        console.log('error')
      }
    } else {
      dispatch(increaseQuantity(id))
    }
  }

  const decreaseQuantityItem = async (id) => {
    if (isLogged) {
      const payload = { item: id, quantity: 1 }
      const { status, dataResponse } = await decreaseQuantityItemCart(payload)
      if (status === 200) {
        dispatch(decreaseQuantity(id))
      } else {
        console.log('error')
      }
    } else {
      dispatch(decreaseQuantity(id))
    }
  }

  const removeProductCart = async (item) => {
    if (isLogged) {
      const payload = { item: item.id }
      const { status, dataResponse } = await removeItemCart(payload)
      if (status === 200) {
        dispatch(removeItem(item))
      } else {
        console.log('error')
      }
    } else {
      // console.log('error', item)
      dispatch(removeItem(item))
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    resolve()
  }

  const urlItem = (item) => `/productos/${item.type}/${item.url}/`

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        !props.isResume ? (
          <StepperAction className="button mine" type="submit" align="right">
            Siguiente
          </StepperAction>
        ) : null
      }
    >
      <Row>
        <Col md={12}>
          {items.map((item, i) => (
            <div key={i} className="cart-item">
              <div className="cart-item__details">
                <Link style={{ cursor: 'pointer' }} to={urlItem(item)}>
                  <Image
                    key={item.id}
                    src={item.get_url}
                    fallbackSrc={preview}
                    height="100"
                  />
                </Link>
                <div style={{ flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                  <Link
                    style={{
                      cursor: 'pointer',
                      color: 'black',
                      textDecoration: 'none',
                    }}
                    to={urlItem(item)}
                  >
                    {item.titulo}
                  </Link>
                  <h6>
                    <strong>{item.ean}</strong>
                  </h6>
                  <span style={{ color: 'black', opacity: 0.4, fontSize: 14 }}>
                    {item.autors}
                  </span>
                </div>
              </div>
              {props.isResume ? (
                <div className="cart-item__resume">
                  <div className="infoQuantyItem">
                    <div className="dataItem">
                      <div className="fieldData">Cantidad:</div>
                      <div className="valueData">{item.quantity}</div>
                    </div>
                  </div>
                  <div className="infoItem">
                    <div className="dataItem">
                      <div className="fieldData">Precio:</div>
                      <div className="valueData">
                        {item.precio_venta_publico} €
                      </div>
                    </div>
                    <div className="dataItem">
                      <div className="fieldData">Total:</div>
                      <div className="valueData">
                        {(item.quantity * item.precio_venta_publico).toFixed(2)}{' '}
                        €
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {!props.isResume ? (
                <div className="cart-item__actions">
                  <div>
                    <h6>
                      Precio: <strong>{item.precio_venta_publico} €</strong>
                    </h6>
                    <div className="add-remove">
                      <Link
                        to="/carrito"
                        onClick={() => decreaseQuantityItem(item.id)}
                        aria-label="añadir una unidad de este producto del carrito"
                      >
                        <AiFillMinusCircle
                          className="icon-md"
                          color="#3a86ff"
                        />
                      </Link>
                      <span>{item.quantity}</span>
                      <Link
                        to="/carrito"
                        onClick={() => increaseQuantityItem(item.id)}
                        aria-label="quitar una unidad de este producto del carrito"
                      >
                        <AiFillPlusCircle className="icon-md" color="#3a86ff" />
                      </Link>
                    </div>{' '}
                  </div>

                  <div>
                    <Link
                      to="/carrito"
                      className="button button--neutral button--small cart-item__delete"
                      aria-label="eliminar este item del carrito"
                      onClick={() => removeProductCart(item)}
                    >
                      <AiFillDelete className="icon-sm" />
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </Col>
      </Row>
      <br />
      <Row className="shippingCosts">
        <Col md={6}>
          <h6>Costos de Envío para España:</h6>
        </Col>
        <Col md={6}>
          <h6>
            <strong>{shippingCosts.toFixed(2)} €</strong>
          </h6>
        </Col>
      </Row>
    </StepperContent>
  )
}

export default Items

import React, { useEffect, useState } from 'react'
import { Nav, Row, Col, Container, NavDropdown } from 'react-bootstrap'
import './footer.scss'
import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai'
import { FaMapMarkerAlt } from 'react-icons/fa'
import SubscriptionForm from '../subscription/subscriptionForm'
import axiosInstance from '../../api/api'
import { getCompany } from '../../api/company'

const Footer = ({
  name,
  brandLogo,
  direccion_completa,
  telefono,
  email,
  redes_sociales,
  data,
  menus,
  has_subscription,
  refundText,
}) => {
  function createMarkup() {
    const text = `Copyright &copy; ${new Date().getFullYear()} ${name}. Todos los derechos reservados.`
    return {
      __html: text,
    }
  }

  const [lat, setLat] = useState()
  const [long, setLong] = useState()

  const path = window.location.host

  const styleImg = {
    width: '100%',
    maxWidth: '90px',
    height: 'auto',
  }
  const styleFilmo = {
    width: '120%',
    maxWidth: '155px',
    height: '55px',
  }

  const goUp = () => {
    window.scrollTo(0, 0)
  }

  const getLatLon = async () => {
    const rs = await getCompany()
    setLat(rs.data.latitude)
    setLong(rs.data.longitude)
  }

  useEffect(() => {
    getLatLon()
  }, [])

  return (
    <footer
      className="footer"
      style={{
        marginTop: '2rem',
      }}
    >
      {has_subscription && <SubscriptionForm />}
      {data ? (
        <Container>
          <Row className="information">
            <Col className="firstSection" xs={12} md={5} xl={2}>
              {data.subencionado ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <a href="/" style={{ margin: '0 auto' }}>
                    <img
                      alt="Subvencionado"
                      style={
                        path === 'llibreriadelafilmo.com'
                          ? styleFilmo
                          : styleImg
                      }
                      src={data.subencionado.logo}
                    />
                  </a>
                  <h6
                    style={{
                      textAlign: 'center',
                      fontSize: 11,
                      fontWeight: 'bold',
                      marginTop: '45px',
                    }}
                  >
                    {data.subencionado.text}
                  </h6>
                </div>
              ) : (
                <Link to={'/'}>
                  <img alt={'Logotipo ' + name} src={brandLogo} />
                </Link>
              )}
            </Col>

            <Col
              className="secondSection contSmall d-sm-none"
              xs={12}
              md={7}
              xl={4}
            >
              <h5 className="title-footer">Contacto</h5>
              <hr />
              <div className="text-footer contact pb-2">
                <span className="contactDetails">
                  <FaMapMarkerAlt
                    role="none"
                    className="icon-md mr-2 "
                    size={35}
                  />
                  <br />
                  <a
                    href={`https://maps.google.com/?q=${lat},${long}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {direccion_completa}
                  </a>
                </span>
              </div>
              <div className="text-footer contact pb-2">
                <AiOutlinePhone
                  role="none"
                  className="icon-md mr-2"
                  size={35}
                />
                <a href={`tel:${telefono.replace(/\s+/g, '')}`}>
                  <p className="contactDetails">{telefono}</p>
                </a>
              </div>
              <div className="text-footer contact pb-2">
                <AiOutlineMail role="none" className="icon-md mr-2" size={35} />
                <a href={`mailto:${email}`}>
                  <p className="contactDetails">{email}</p>
                </a>
              </div>
            </Col>

            <Col
              className="secondSection contBig d-none d-sm-block"
              xs={12}
              md={7}
              xl={4}
            >
              <h5 className="title-footer">Contacto</h5>
              <hr />
              <div className="text-footer contact pb-2">
                <span className="contactDetails">
                  <FaMapMarkerAlt
                    role="none"
                    className="icon-md mr-2"
                    size={21}
                  />
                  <a
                    href={`https://maps.google.com/?q=${lat},${long}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {direccion_completa}
                  </a>
                </span>
              </div>
              <div className="text-footer contact pb-2">
                <AiOutlinePhone
                  role="none"
                  className="icon-md mr-2"
                  size={21}
                />
                <a href={`tel:${telefono.replace(/\s+/g, '')}`}>
                  <p className="contactDetails">{telefono}</p>
                </a>
              </div>
              <div className="text-footer contact pb-2">
                <AiOutlineMail role="none" className="icon-md mr-2" size={21} />
                <a href={`mailto:${email}`}>
                  <p className="contactDetails">{email}</p>
                </a>
              </div>
            </Col>

            <Col className="thirdSection" xs={12} md={6} xl={3}>
              <h5 className="title-footer">Secciones</h5>
              <hr />
              <div className="table-footer">
                    
                <Row xs={2} md={2} style={{ width: '-webkit-fill-available' }}>
                  <Nav.Link href="/">
                    <p className="text-footer">Inicio</p>
                  </Nav.Link>
                  {menus.map((item, i) =>
                    item.submenus && item.submenus.items ? null : (
                      <Nav.Link href={item.slug} key={i}>
                        <p className="text-footer">{item.name}</p>
                      </Nav.Link>
                    )
                  )}
                </Row>
              </div>
            </Col>

            {redes_sociales ? (
              <Col className="lastSection" xs={12} md={6} xl={3}>
                <h5 className="title-footer">Síguenos</h5>
                <hr />
                <div className="socialIcons">
                  {Object.keys(redes_sociales).map((item, i) => (
                    <SocialIcon
                      network={item}
                      key={i}
                      target="_blank"
                      url={redes_sociales[item]}
                    />
                  ))}
                </div>
              </Col>
            ) : (
              false
            )}
          </Row>

          <div className="bottom-footer">
            <Container fluid>
              <Row className="copyright">
                <div className="linksPrivacity">
                  <p className="text-footer">
                    <Link to="/condiciones" onClick={goUp}>
                      Términos y condiciones{' '}
                    </Link>
                  </p>
                  <p className="text-footer">
                    <Link to="/aviso-legal" onClick={goUp}>
                      Aviso Legal
                    </Link>
                  </p>
                  {refundText && (
                    <p className="text-footer">
                      <Link to="/devoluciones-y-anulaciones" onClick={goUp}>
                        Devoluciones y anulaciones
                      </Link>
                    </p>
                  )}
                </div>
              </Row>
              <Row className="copyright">
                <div
                  className="markup"
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </Row>
            </Container>
          </div>
        </Container>
      ) : null}
    </footer>
  )
}

export default Footer

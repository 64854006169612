import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addItem } from '../../slices/cart'
import { Button, Row, Col } from 'react-bootstrap'
// import { AiOutlineInfoCircle, AiOutlineShoppingCart } from 'react-icons/ai'
import { Link, useParams } from 'react-router-dom'
import { checkEmpty } from '../../helpers'
import CoverImg from '../coverImage/CoverImage'
import { useSelector } from 'react-redux'
import { userSelector } from '../../slices/user'
import { addItemCart } from '../../api/ecommerce'
//preview para libros sin portada
import preview from '../../assets/img/preview.jpg'
//api
import { getDetailProduct } from '../../api/products'

const Item = (props) => {
  const { data } = props
  const urlItem = checkEmpty(data) ? `/productos/${data.type}/${data.url}/` : ''
  const dispatch = useDispatch()
  const { isLogged } = useSelector(userSelector)
  let { type } = useParams()
  const [autorUrl, setAutorUrl] = useState('')

  const getAuthor = async () => {
    const rs = await getDetailProduct(data?.url, { type })
    if (rs.status === 200) {
      const { data } = rs
      setAutorUrl(data.autor[0]?.url)
    }
  }

  // useEffect(() => {
  //   getAuthor()
  // }, [])

  // const addProductToCart = async () => {
  //   if (isLogged) {
  //     const payload = { item: data.id, quantity: 1 }
  //     const { status, dataResponse } = await addItemCart(payload)
  //     if (status === 201) {
  //       dispatch(addItem(data))
  //     } else {
  //       console.log('error')
  //     }
  //   } else {
  //     dispatch(addItem(data))
  //   }
  // }

  const goUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className="store-item mx-3">
      <Row className="store-cover">
        <div className="img-container">
          <div className="overlay"></div>
          {data.get_url !== '' || data.cover !== '' ? (
            <Link to={urlItem} onClick={goUp}>
              <CoverImg
                src={data.get_url ? data.get_url : data.cover}
                style={{ marginLeft: '1rem' }}
              />
            </Link>
          ) : (
            <Link to={urlItem} onClick={goUp}>
              <CoverImg src={preview} />
            </Link>
          )}
          {/* <span> */}
          {/* <Link to={urlItem} onClick={goUp}>
              <button
                aria-label="ver producto"
                title="ver producto"
                className="button button--icon button--outline-hover"
              > */}
          {/* <AiOutlineInfoCircle /> */}
          {/* </button> */}
          {/* </Link> */}
          {/* </span> */}
        </div>
      </Row>
      <Row className="img-caption">
        <Col xs={12}>
          <Link className="store-item__link" to={urlItem}>
            <h5 className="title limit-long" title={data.titulo}>
              {data.titulo}
            </h5>
          </Link>
        </Col>
        <Col xs={12}>
          {data.autors && (
            <Link className="store.item__link" to={`/autor/${autorUrl}`}>
              <h6 className="subtitle limit-long" title={data.autors}>
                {data.autors}
              </h6>
            </Link>
          )}
        </Col>
      </Row>
      <Link to={urlItem} onClick={goUp}>
        <Button variant="outline-dark" size="sm" title="ver producto" block>
          {data.precio_venta_publico} €
        </Button>
      </Link>
    </div>
  )
}

export default memo(Item, (prev, next) => {
  return prev.data.id === next.data.id
})

import React, { useEffect } from 'react'
import 'react-material-stepper/dist/react-stepper.css'
import { Row, Col, Container, Jumbotron } from 'react-bootstrap'
import './cart.scss'
import { errorPayment } from '../../api/ecommerce'

const ErrorPayment = () => {

  const callErrorPayment = async () => {
    try {
      const { status, data } = await errorPayment()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    callErrorPayment()
  }, [])
  
  return (
    <Container>
      <Row>
        <Col md={12} className="title">
          <h2>Carrito de Compras</h2>
          <span className="divider-title"></span>
        </Col>
      </Row>      
      <Row>
        <Col>
          <Jumbotron style={{ textAlign: 'center' }}>
            <p>Error en el pago.</p>
            <p>Ha ocurrido un error con su tarjeta, el pedido no ha sido procesado.</p>
          </Jumbotron>          
        </Col>
      </Row>
    </Container>
  )
}

export default ErrorPayment

import React, { useState, useEffect, useRef } from 'react'
import { Spinner, Card, Row, Col } from 'react-bootstrap'
import ItemSearch from '../../components/itemSearch'
import { AiOutlineSearch } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'

const cardStyle = {
  position: 'absolute',
  zIndex: '1',
  width: '96%',
}

const footerCardStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  fontFamily: 'sans-serif',
  fontWeight: 'bolder',
  color: '#5D7CA6',
  width: '100%',
}
const headerCardStyle = {
  fontSize: '18px',
  fontFamily: 'sans-serif',
  fontWeight: 'bolder',
  display: 'flex',
  justifyContent: 'space-between',
}
const centerChild = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const iconClose = {
  cursor: 'pointer',
}

function useOutsideAlerter(ref, func) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [func, ref])
}

const SearchResult = (props) => {
  const [showResults, setshowResults] = useState(true)
  let history = useHistory()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props.onBlur)

  return (
    <>
      {showResults ? (
        <Card
          className="instant-search text-center"
          id="searchContainer"
          ref={wrapperRef}
          style={cardStyle}
        >
          <Card.Body className="instant-search__title">
            <Card.Title style={headerCardStyle}>
              <span>Resultados</span>
              <span
                id="closeSearchContainer"
                style={iconClose}
                onClick={props.handleBlur}
              >
                x
              </span>
            </Card.Title>
          </Card.Body>
          <Card.Body className="instant-search__results">
            <Card.Text>
              <Row className="margin-top-md">
                {props.results !== null && props.results.length > 0 ? (
                  props.results.map((item, i) => (
                    <Col md={4} style={centerChild} onClick={props.onBlur}>
                      <ItemSearch key={i} data={item} />
                    </Col>
                  ))
                ) : props.results !== null && props.results.length == 0 ? (
                  <Col md={12}>
                    <h2 className="my-5">NO HAY RESULTADOS</h2>
                  </Col>
                ) : (
                  <Col md={12}>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Col>
                )}
              </Row>
            </Card.Text>
          </Card.Body>
          <Card.Footer className="text-muted">
            <button
              style={footerCardStyle}
              onClick={(e) => {
                e.stopPropagation()
                setshowResults(false)
                setTimeout(() => {
                  history.push('/buscador-avanzado')
                }, 1000)
              }}
            >
              <AiOutlineSearch className="icon-md" />
              <span>Ir al Buscador avanzado</span>
            </button>
          </Card.Footer>
        </Card>
      ) : null}
    </>
  )
}

export default SearchResult

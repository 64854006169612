import React, { useState, useEffect } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'

import { InputCustom } from '../../components/input/index'
import {
  getCountries,
  getProvinces,
  getLocalitiesByProvince,
} from '../../api/company'
import { Autocomplete } from '../../components/autocomplete'

const Address = ({ userInvalid, formik }) => {
  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [localities, setLocalities] = useState([])
  const [allLocalities, setAllLocalities] = useState([])
  const [postalCodes, setPostalCode] = useState([])

  const getAllCountries = async () => {
    try {
      const { data } = await getCountries()
      const resultsArray = data.map((result) => ({
        label: result['nombre'],
        value: result['id'],
      }))

      setCountries(resultsArray)
    } catch (e) {}
  }

  const getAllProvincesByCountry = async (query) => {
    try {
      const { data } = await getProvinces(query)
      const resultsArray = data.map((result) => ({
        label: result['nombre'],
        value: result['id'],
      }))
      setProvinces(resultsArray)
    } catch (e) {}
  }

  const getAllLocalitiesByProvince = async (query) => {
    try {
      const { data } = await getLocalitiesByProvince(query)
      const resultsArray = data.map((result) => ({
        label: result['nombre'],
        value: result['cod_postal'],
      }))
      setAllLocalities(resultsArray)

      let localities = resultsArray
        .map((item) => item.label)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((result) => ({
          label: result,
          value: result,
        }))
      setLocalities(localities)
    } catch (e) {}
  }

  const getAllPostalCode = async (query) => {
    let resultsArray = allLocalities
      .filter((result) => result['label'] === query)
      .map((result) => ({
        label: result['value'],
        value: result['label'],
      }))
    setPostalCode(resultsArray)
  }

  useEffect(() => {
    getAllProvincesByCountry(formik.values.country)
    //eslint-disable-next-line
  }, [])

  const changeCountry = (value) => {
    getAllProvincesByCountry(value.value)
    formik.setValues({
      ...formik.values,
      country: value.label,
      province: '',
      postal_code: '',
      locality: '',
    })
  }

  const changeProvince = (value) => {
    console.log("here billing");
    getAllLocalitiesByProvince(value.value)
    formik.setValues({
      ...formik.values,
      billingAddress: {
        ...formik.values.billingAddress,
        province: value.label,
        locality: '',
        postal_code: '',
      }
      
    })
  }

  const changeLocality = (value) => {
    console.log("here billing");
    formik.setValues({
      ...formik.values,
      billingAddress: {
        ...formik.values.billingAddress,
        locality: value.label,
        postal_code: '',
      }
    })
    getAllPostalCode(value.value)
  }

  const changePostalCode = (value) => {
    formik.setFieldValue('postal_code', value.label)
  }

  const { errors, touched, values, handleChange, handleBlur } = formik

  return (
    <>
      <Row>
        <Col md={4}>
          <InputCustom
            id="first_name"
            name={`billingAddress.first_name`}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            label="Nombre"
            value={values.billingAddress?.first_name}
            placeholder={'Ingrese su nombre'}
            isInvalid={errors.billingAddress?.first_name && touched.billingAddress?.first_name}
            error={errors.billingAddress?.first_name}
          />
        </Col>
        <Col md={4}>
          <InputCustom
            id="last_name"
            name={`billingAddress.last_name`}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            label="Apellido"
            value={values.billingAddress?.last_name}
            placeholder={'Ingrese su apellido'}
            isInvalid={errors.billingAddress?.last_name && touched.billingAddress?.last_name}
            error={errors.billingAddress?.last_name}
          />
        </Col>
        <Col md={4}>
          <InputCustom
            id="email"
            name={`billingAddress.email`}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            label="Correo"
            type="email"
            placeholder={'Ingrese su correo'}
            isInvalid={errors.billingAddress?.email && touched.billingAddress?.email}
            value={values.billingAddress?.email}
            error={errors.billingAddress?.email}
          />
        </Col>
        <Col md={4}>
          <InputCustom
            id="phone"
            name={`billingAddress.phone`}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            label="Telefono"
            placeholder={'Ingrese su teléfono'}
            value={values.billingAddress?.phone}
            isInvalid={errors.billingAddress?.phone && touched.billingAddress?.phone}
            error={errors.billingAddress?.phone}
          />
        </Col>
        <Col md={4}>
          <InputCustom
            name={`billingAddress.address`}
            id="address"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            label="Dirección"
            placeholder={'Ingrese su dirección'}
            value={values.billingAddress?.address}
            isInvalid={errors.billingAddress?.address && touched.billingAddress?.address}
            error={errors.billingAddress?.address}
          />
        </Col>
        <Col md={4}>
          <Autocomplete
            id="country"
            isDisabled={true}
            required
            label="País"
            asyncFetch={countries}
            onSelect={(value) => {
              changeCountry(value)
            }}
            placeholder={'País'}
            inputValue={{ label: 'España', value: 'España' }}
          />
        </Col>
        <Col md={4}>
          <Autocomplete
            id="province"
            required
            label="Provincia"
            asyncFetch={provinces}
            onSelect={(value) => {
              changeProvince(value)
            }}
            onBlur={handleBlur}
            placeholder={'Provincia'}
            inputValue={{ label: values.billingAddress?.province, value: values.billingAddress?.province }}
            showError={errors.billingAddress?.province && touched.billingAddress?.province}
            errorMessage={errors.billingAddress?.province}
          />
        </Col>
        <Col md={4}>
          <Autocomplete
            id="locality"
            required
            label="Localidad"
            asyncFetch={localities}
            onSelect={(value) => changeLocality(value)}
            onBlur={handleBlur}
            placeholder={'Localidad'}
            inputValue={{ label: values.billingAddress?.locality, value: values.billingAddress?.locality }}
            showError={errors.billingAddress?.locality && touched.billingAddress?.locality}
            errorMessage={errors.billingAddress?.locality}
          />
        </Col>
        <Col md={4}>
          <InputCustom
            id="postal_code"
            name={`billingAddress.postal_code`}
            type="text"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            label="Código Postal"
            placeholder={'Código postal'}
            isInvalid={errors.billingAddress?.postal_code && touched.billingAddress?.postal_code}
            value={values.billingAddress?.postal_code}
            error={errors.billingAddress?.postal_code}
          />
        </Col>
      </Row>
      {userInvalid ? (
        <Row>
          <Col md={6}>
            <Alert className="flex-row" variant="warning">
              Tipo de usuario no valido
            </Alert>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default Address

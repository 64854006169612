import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import delay from 'delay'

// own components
import { InputCustom } from '../../components/input'
import Card from '../../components/card'

// own api
import { changePassword, infoUser, saveChanges } from '../../api/users'
import { getCountries } from '../../api/company'

import { COMPANY } from '../../constans'

import './profile.scss'

const Profile = () => {
  const [countries, setCountries] = useState([])
  const [disableBtnChangePass, setDisableBtnChangePass] = useState(false)
  const [disableBtnSave, setDisableBtnSave] = useState(false)
  const [errorChangePass, setErrorChangePass] = useState('')
  const [successChangePass, setSuccessChangePass] = useState(false)
  const [errorSave, setErrorSave] = useState('')
  const [successSave, setSuccessSave] = useState(0)
  const [formInfo, setFormInfo] = useState({
    username: '',
    cif: '',
    name: '',
    last_name: '',
    email: '',
    direccion_facturacion: {},
    direccion_envio: {}
  })
  const [formChangePass, setFormChangePass] = useState({
    oldPass: '',
    newPass: '',
    repeatPass: '',
  })

  useEffect(() => {
    async function handleCountries() {
      const rs = await getCountries()
      if (rs.status === 200) {
        const c = rs.data.map((item) => ({
          value: item.codigo,
          label: item.nombre,
        }))
        setCountries(c)
      }
    }
    async function handleInfoUser() {
      const rs = await infoUser()
      if (rs.status === 200) {
        if (rs.data.benefited_bookstore) {
          delete rs.data.benefited_bookstore
        }
        console.log(rs.data);
        setFormInfo({ ...formInfo, ...rs.data })
      }
    }
    handleCountries()
    handleInfoUser()
    window.document.title = 'Mi cuenta | ' + COMPANY
    // eslint-disable-next-line
  }, [])

  const handleChangePass = async () => {
    setDisableBtnChangePass(true)
    setErrorChangePass('')
    setSuccessChangePass(false)
    const { oldPass, newPass, repeatPass } = formChangePass
    if (oldPass !== '' && newPass !== '' && repeatPass !== '') {
      const rs = await changePassword(formChangePass)
      if (rs.status === 200) {
        setFormChangePass({
          oldPass: '',
          newPass: '',
          repeatPass: '',
        })
        setSuccessChangePass(true)
        await delay(4000)
        setSuccessChangePass(false)
      } else if (rs.status === 400) {
        console.log('called')
        setErrorChangePass(rs.data.msg)
      }
      setDisableBtnChangePass(false)
    } else {
      setErrorChangePass('Todos los campos son obligatorios')
      setDisableBtnChangePass(false)
    }
  }

  const handleChangeSave = async (data) => {
    setDisableBtnSave(true)
    setDisableBtnSave(true)
    setErrorSave('')
    setSuccessSave(false)
    const rs = await saveChanges(formInfo)
    if (rs.status === 200) {
      setSuccessSave(data)
      setDisableBtnSave(false)
      await delay(4000)
      setSuccessSave(0)
    } else {
      setErrorSave('Algo ha ido mal, por favor, vuelve a intentarlo')
      setDisableBtnSave(false)
    }
  }

  return (
    <Container>
      <Card className="margin-top-md" title="Mi cuenta">
        <Row>
          <Col md={4}>
            <InputCustom
              type="text"
              disabled
              value={formInfo.username}
              onChange={(e) =>
                setFormInfo({ ...formInfo, username: e.target.value })
              }
              placeholder="Nombre de usuario"
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="CIF"
              value={formInfo.cif}
              onChange={(e) =>
                setFormInfo({ ...formInfo, cif: e.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Nombre"
              value={formInfo.name}
              onChange={(e) =>
                setFormInfo({ ...formInfo, name: e.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Apellidos"
              value={formInfo.last_name}
              onChange={(e) =>
                setFormInfo({ ...formInfo, last_name: e.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="email"
              placeholder="Correo electrónico"
              value={formInfo.email}
              onChange={(e) =>
                setFormInfo({ ...formInfo, email: e.target.value })
              }
            />
          </Col>
        </Row>
        {errorSave !== '' ? (
          <Row className="alert-danger">
            <span>{errorSave}</span>
          </Row>
        ) : null}
        {successSave === 1 ? (
          <Row className="alert-success">
            <span>Los cambios se guardaron correctamente</span>
          </Row>
        ) : null}
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button
              variant="outline-dark"
              disabled={disableBtnSave}
              className="btn-round"
              size="sm"
              onClick={()=>handleChangeSave(1)}
            >
              {disableBtnSave ? (
                <Spinner animation="grow" variant="dark" />
              ) : (
                <span>Guardar cambios</span>
              )}
            </Button>
          </Col>
        </Row>
      </Card>

      <Card className="margin-top-sm" title="Dirección de facturación">
        <Row>
          <Col md={4}>
            <InputCustom
              type="tel"
              placeholder="Teléfono"
              value={formInfo.direccion_facturacion.phone}
              onChange={(e) => {
                const df = {...formInfo.direccion_facturacion, phone: e.target.value}
                setFormInfo({ ...formInfo, direccion_facturacion: df })
              }}
            />
          </Col>
          <Col md={4}>
            <Select
              placeholder="País"
              options={countries}
              defaultValue={formInfo.direccion_facturacion.country}
              value={formInfo.direccion_facturacion.country}
              onChange={(e) => {
                const df = {...formInfo.direccion_facturacion, country: {value: e.value, label: e.label}}
                setFormInfo({ ...formInfo, direccion_facturacion: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Dirección"
              value={formInfo.direccion_facturacion.address}
              onChange={(e) => {
                const df = {...formInfo.direccion_facturacion, address: e.target.value}
                setFormInfo({ ...formInfo, direccion_facturacion: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Provincia"
              value={formInfo.direccion_facturacion.province}
              onChange={(e) => {
                const df = {...formInfo.direccion_facturacion, province: e.target.value}
                setFormInfo({ ...formInfo, direccion_facturacion: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Localidad"
              value={formInfo.direccion_facturacion.locality}
              onChange={(e) =>{
                const df = {...formInfo.direccion_facturacion, locality: e.target.value}
                setFormInfo({ ...formInfo, direccion_facturacion: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              name="postalcode"
              placeholder="Código postal"
              value={formInfo.direccion_facturacion.postal_code}
              onChange={(e) => {
                const df = {...formInfo.direccion_facturacion, postal_code: e.target.value}
                setFormInfo({ ...formInfo, direccion_facturacion: df })
              }}
            />
          </Col>
        </Row>
        {errorSave !== '' ? (
          <Row className="alert-danger">
            <span>{errorSave}</span>
          </Row>
        ) : null}
        {successSave === 2 ? (
          <Row className="alert-success">
            <span>Los cambios se guardaron correctamente</span>
          </Row>
        ) : null}
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button
              variant="outline-dark"
              disabled={disableBtnSave}
              className="btn-round"
              size="sm"
              onClick={()=>handleChangeSave(2)}
            >
              {disableBtnSave ? (
                <Spinner animation="grow" variant="dark" />
              ) : (
                <span>Guardar cambios</span>
              )}
            </Button>
          </Col>
        </Row>
      </Card>

      <Card className="margin-top-sm" title="Dirección de envíos">
        <Row>
          <Col md={4}>
            <InputCustom
              type="tel"
              placeholder="Teléfono"
              value={formInfo.direccion_envio.phone}
              onChange={(e) => {
                const df = {...formInfo.direccion_envio, phone: e.target.value}
                setFormInfo({ ...formInfo, direccion_envio: df })
              }}
            />
          </Col>
          <Col md={4}>
            <Select
              placeholder="País"
              options={countries}
              defaultValue={formInfo.direccion_envio.country}
              value={formInfo.direccion_envio.country}
              onChange={(e) => {
                const df = {...formInfo.direccion_envio, country: {value: e.value, label: e.label}}
                setFormInfo({ ...formInfo, direccion_envio: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Dirección"
              value={formInfo.direccion_envio.address}
              onChange={(e) => {
                const df = {...formInfo.direccion_envio, address: e.target.value}
                setFormInfo({ ...formInfo, direccion_envio: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Provincia"
              value={formInfo.direccion_envio.province}
              onChange={(e) => {
                const df = {...formInfo.direccion_envio, province: e.target.value}
                setFormInfo({ ...formInfo, direccion_envio: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              placeholder="Localidad"
              value={formInfo.direccion_envio.locality}
              onChange={(e) =>{
                const df = {...formInfo.direccion_envio, locality: e.target.value}
                setFormInfo({ ...formInfo, direccion_envio: df })
              }}
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="text"
              name="postalcode"
              placeholder="Código postal"
              value={formInfo.direccion_envio.postal_code}
              onChange={(e) => {
                const df = {...formInfo.direccion_envio, postal_code: e.target.value}
                setFormInfo({ ...formInfo, direccion_envio: df })
              }}
            />
          </Col>
        </Row>
        {errorSave !== '' ? (
          <Row className="alert-danger">
            <span>{errorSave}</span>
          </Row>
        ) : null}
        {successSave === 3 ? (
          <Row className="alert-success">
            <span>Los cambios se guardaron correctamente</span>
          </Row>
        ) : null}
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button
              variant="outline-dark"
              disabled={disableBtnSave}
              className="btn-round"
              size="sm"
              onClick={()=>handleChangeSave(3)}
            >
              {disableBtnSave ? (
                <Spinner animation="grow" variant="dark" />
              ) : (
                <span>Guardar cambios</span>
              )}
            </Button>
          </Col>
        </Row>
      </Card>

      <Card className="margin-top-sm" title="Cambiar contraseña">
        <Row>
          <Col md={4}>
            <InputCustom
              type="password"
              value={formChangePass.oldPass}
              placeholder="Contraseña actual"
              onChange={(e) =>
                setFormChangePass({
                  ...formChangePass,
                  oldPass: e.target.value,
                })
              }
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="password"
              value={formChangePass.newPass}
              placeholder="Nueva contraseña"
              onChange={(e) =>
                setFormChangePass({
                  ...formChangePass,
                  newPass: e.target.value,
                })
              }
            />
          </Col>
          <Col md={4}>
            <InputCustom
              type="password"
              value={formChangePass.repeatPass}
              placeholder="Repetir contraseña nueva"
              onChange={(e) =>
                setFormChangePass({
                  ...formChangePass,
                  repeatPass: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        {errorChangePass !== '' ? (
          <Row className="alert-danger">
            <span>{errorChangePass}</span>
          </Row>
        ) : null}
        {successChangePass ? (
          <Row className="alert-success">
            <span>Su contraseña se ha actualizado correctamente</span>
          </Row>
        ) : null}
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button
              variant="outline-dark"
              className="btn-round"
              disabled={disableBtnChangePass}
              size="sm"
              onClick={handleChangePass}
            >
              {disableBtnChangePass ? (
                <Spinner animation="grow" variant="dark" />
              ) : (
                <span>Cambiar contraseña</span>
              )}
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  )
}

export default Profile

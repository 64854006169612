import React from 'react'

import { Form } from 'react-bootstrap'

export const InputCustom = ({ error, ...props }) => {
  return (
    <Form.Group>
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      <Form.Control
        className={props.icon ? 'input-text-icon' : 'input-text'}
        type={props.type}
        placeholder={props.placeholder}
        {...props}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  )
}

export const SelectInputCustom = (props) => {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        placeholder={'pruebas'}
        as="select"
        custom
        onChange={(e) => props.onCheckedItem(e.target.value)}
        value={props.value}
      >
        {props.choices.map((choice, i) => (
          <option value={choice.id} key={choice.id}>
            {choice.value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

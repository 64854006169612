import { Form } from 'react-bootstrap'
import React from 'react'

import Select from 'react-select'

export const Autocomplete = ({
  asyncFetch,
  label,
  onSelect,
  inputValue,
  placeholder,
  isDisabled,
  id,
  showError = false,
  errorMessage,
  ...props
}) => {
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        isDisabled={isDisabled}
        value={inputValue}
        options={asyncFetch}
        onChange={(option) => {
          onSelect(option)
        }}
        placeholder={placeholder}
        noOptionsMessage={() => 'Sin Resultados'}
        defaultValue={asyncFetch[0]}
        inputId={id}
        {...props}
      />
      {showError && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errorMessage}
        </div>
      )}
    </Form.Group>
  )
}

import * as Yup from 'yup'
import 'yup-phone'
import formText, { requiredText } from './formText'
import { phoneRegex } from '../constans'

export const FullFormSchema = Yup.object().shape({
  billingAddress: Yup.object({
    first_name: Yup.string().max(40).required(requiredText),
    last_name: Yup.string().max(40).required(requiredText),
    email: Yup.string()
      .email(formText.email.error)
      .required(formText.email.required),
    phone: Yup.string().matches(phoneRegex, 'Ingrese un número de teléfono válido'),
    address: Yup.string().required(requiredText),
    country: Yup.string(),
    province: Yup.string().required(requiredText),
    locality: Yup.string().required(requiredText),
    postal_code: Yup.string()
      .matches(
        /^[0-9]{5}$/,
        'Código postal incorrecto. Debes ingresar hasta 5 dígitos'
      )
      .required(requiredText)
  }),
  shippingAddress: Yup.object({
    first_name: Yup.string().max(40).required(requiredText),
    last_name: Yup.string().max(40).required(requiredText),
    email: Yup.string()
      .email(formText.email.error)
      .required(formText.email.required),
    phone: Yup.string().matches(phoneRegex, 'Ingrese un número de teléfono válido'),
    address: Yup.string().required(requiredText),
    country: Yup.string(),
    province: Yup.string().required(requiredText),
    locality: Yup.string().required(requiredText),
    postal_code: Yup.string()
      .matches(
        /^[0-9]{5}$/,
        'Código postal incorrecto. Debes ingresar hasta 5 dígitos'
      )
      .required(requiredText)
  })
})

export const ShippingFormSchema =  Yup.object().shape({
  shippingAddress: Yup.object({
    first_name: Yup.string().max(40).required(requiredText),
    last_name: Yup.string().max(40).required(requiredText),
    email: Yup.string()
      .email(formText.email.error)
      .required(formText.email.required),
    phone: Yup.string().matches(phoneRegex, 'Ingrese un número de teléfono válido'),
    address: Yup.string().required(requiredText),
    country: Yup.string(),
    province: Yup.string().required(requiredText),
    locality: Yup.string().required(requiredText),
    postal_code: Yup.string()
      .matches(
        /^[0-9]{5}$/,
        'Código postal incorrecto. Debes ingresar hasta 5 dígitos'
      )
      .required(requiredText)
  })
})

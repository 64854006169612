export const API_INFO = process.env.REACT_APP_API_INFO
export const COMPANY = "Ocho y medio"
export const DESCRITION = "Libros de cine"
export const MONTH_DIS = [
  "ENE",
  "FEB",
  "MAR",
  "ABR",
  "MAY",
  "JUN",
  "JUL",
  "AGO",
  "SEP",
  "OCT",
  "NOV",
  "DIC"
]
export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/
export const latinCharsRegex = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]{1,45})$/
export const phoneRegex = /^([0-9\s]{6,20})$/
export const orderRegex = /^([0-9]{3,6})$/
export const themeBase = ':root{--color-primario:#000000;--color-secundario:#D9DBDE;--color-terciario:#0069d9;--color-sombra:#8f8f8f;--texto-primario:#000000;--texto-secundario:#FFFFFF;--boton-primario:#0069d9;--boton-secundario:#FFFFFF;--fuente-primaria: montserrat;}'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

//own
import { truncate } from '../../helpers'

import { companySelector } from '../../slices/company.js'
import { useSelector } from 'react-redux'

const Head = (props) => {
  const { name } = useSelector(companySelector)
  let description = props.description
  var metaTitle

  if (props.home === true) {
    metaTitle = name + ' | Venta de Libros, Películas y Música'
  } else {
    metaTitle = props.title + ' | ' + name
  }

  if (props.title.indexOf('Productos') !== -1) {
    metaTitle = props.title
  }
  window.document.title = metaTitle

  return (
    <div className="application">
      <Helmet>
        {props.noIndex ? (
          <meta name="robot" content="noindex, nofollow" />
        ) : null}
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="title" content={metaTitle} />
        <meta
          name="description"
          content={truncate(description ? description : '', 180)}
        />
        <title>{metaTitle}</title>
      </Helmet>
    </div>
  )
}

export default Head

import React from 'react'
import { Link } from 'react-router-dom'

const Authors = (props) => {
  return props.elements.map((value, i) => (
    <>
      <Link className="store-item__link" to={`/autor/${value.url}/`}>
        <span>{value.nombre_autor}</span>
      </Link>
      ,
    </>
  ))
}

export default Authors

import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import img from '../../assets/img/404.JPG'

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col>
          <img
            alt="Not Found"
            src={img}
            style={{
              maxWidth: '100%',
              height: 'auto',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2
            style={{ textAlign: 'center', marginTop: '2rem' }}
            className="title"
          >
            Lo sentimos, algo ha fallado
          </h2>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound

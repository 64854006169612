import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { AiOutlineUser } from 'react-icons/ai'

const DropMenuLogged = (props) => {
  return (
    <DropdownButton
      drop={props.dropDirection}
      className="btn-logged header__button header__button--logged"
      size="small"
      id="dropdown-basic-button"
      title={<AiOutlineUser className="icon-sm" />}
    >
      <Dropdown.Item href="/perfil">Perfil</Dropdown.Item>
      <Dropdown.Item href="/mis-pedidos">Mis Pedidos</Dropdown.Item>
      {/*<Dropdown.Item href="/profile">Pedidos</Dropdown.Item>
      <Dropdown.Item href="#">Pedidos especiales</Dropdown.Item>
      <Dropdown.Item href="#">Control pedidos bibliotecas</Dropdown.Item>*/}
      <Dropdown.Item href="/favoritos">Mis guardados</Dropdown.Item>
      <Dropdown.Item onClick={() => props.closeSession()}>
        Cerrar sesión
      </Dropdown.Item>
    </DropdownButton>
  )
}

export default DropMenuLogged

import React , { useState } from 'react';

const Image = (props) => {
  const [src, setSrc] = useState(props.src)
  const [errored, setErrored] = useState(false)

  const onError = () => {
    if (!errored) {
      setSrc(props.fallbackSrc)
      setErrored(true)
    }
  }

  return (
    <img
      alt="imag"
      src={src}
      onError={onError}
      width={props.width} height={props.height}
    />
  )
}

export default Image;
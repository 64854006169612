import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import cartReducer from './cart'
import userReducer from './user'
import companyReducer from './company'

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  company: companyReducer
})

export const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
import DOMPurify from 'dompurify'

export function cleanHTML(input) {
  return DOMPurify.sanitize(input)
}

export const convertMetatagName = (metatag) =>
  metatag.toLowerCase().replace(/ /g, '-').replace(/%/g, '_')

export const recoverMetatagName = (metatag) =>
  metatag.toUpperCase().replace(/-/g, ' ').replace(/_/, '%')

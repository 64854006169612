import React, { useEffect, useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import { Accordion, Card } from 'react-bootstrap'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { InputCustom } from '../input/index'
import { getPublishedCountries, getLanguageProducts } from '../../api/products'
import 'rc-slider/assets/index.css'

import Head from '../../components/metatags_helmet/metatags'

const FilterProductBooks = ({ filterProductsHandle }) => {
  const [isReady, setIsReady] = useState(false)
  const [pubCountries, setPubCountries] = useState([])
  const [lanProducts, setLanProducts] = useState([])
  const [countriesChecked, setcountriesChecked] = useState([])
  const [rangeDatePub, setRangeDatePub] = useState([])
  const [lanChecked, setlanChecked] = useState([])
  const [materiasChecked, setMateriasChecked] = useState([])
  const [featuredMateriasChecked, setfeaturedMateriasChecked] = useState([])
  const [querySearch, setquerySearch] = useState({})
  const { type } = useParams()
  const title = type
  var waitTimer = useRef(null)
  const WAIT_INTERVAL = 1200

  const onChangeFilter = (event, inputName) => {
    setquerySearch({ ...querySearch, [inputName]: event.target.value })
  }

  const onCheckedCountry = (target) => {
    const checked = target.checked
    const country = target.value

    if (checked) {
      setcountriesChecked((countries) => [country, ...countries])
    } else {
      const removeCountry = countriesChecked.filter((e) => e !== country)
      setcountriesChecked(removeCountry)
    }
  }
  const onCheckedLan = (target) => {
    const checked = target.checked
    const lan = target.value
    if (checked) {
      setlanChecked([...lanChecked, lan])
    } else {
      const removeLan = lanChecked.filter((e) => e !== lan)
      setlanChecked(removeLan)
    }
  }

  const nf = new Intl.NumberFormat('de-DE')

  const PubCountryRadios = pubCountries?.map((val, i) => (
    <Form.Check
      key={i}
      id={val.codigo}
      type="checkbox"
      value={val.id}
      onChange={(e) => onCheckedCountry(e.target)}
      label={`${val.nombre} (${nf.format(val.num_books)})`}
    />
  ))

  const lanProductRadios = lanProducts?.map((val, i) => (
    <Form.Check
      key={val.id}
      id={val.id}
      value={val.id}
      type="checkbox"
      onChange={(e) => onCheckedLan(e.target)}
      label={`${val.nombre} (${nf.format(val.num_books)})`}
    />
  ))

  const getCountries = async () => {
    const { data } = await getPublishedCountries({ type })
    setPubCountries(data)
  }

  const getLanguageProduct = async () => {
    const { data } = await getLanguageProducts({ type })
    setLanProducts(data)
  }

  useEffect(() => {
    // initial fetch
    setquerySearch({ ...querySearch, type })
    ;(async () => {
      await getCountries()
      await getLanguageProduct()
      setIsReady(true)
    })()
  }, [type])

  useEffect(() => {
    const searchQuery = {
      ...querySearch,
      ...(countriesChecked.length && {
        pais_public: countriesChecked.join(','),
      }),
      ...(lanChecked.length && { idioma: lanChecked.join(',') }),
    }
    if (searchQuery !== 0) {
      if (isReady) {
        filterProductsHandle(searchQuery)
        const getCountries = async () => {
          const { data } = await getPublishedCountries(searchQuery)
          setPubCountries(data)
        }
        const getLanguageProduct = async () => {
          const { data } = await getLanguageProducts(searchQuery)
          setLanProducts(data)
        }

        const triggerChange = () => {
          getCountries()
          getLanguageProduct()
        }

        clearTimeout(waitTimer.current)
        waitTimer.current = setTimeout(triggerChange, WAIT_INTERVAL)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countriesChecked,
    filterProductsHandle,
    lanChecked,
    querySearch,
    rangeDatePub,
    materiasChecked,
    featuredMateriasChecked,
  ])

  return (
    <div>
      <Head title={title} />
      <Form>
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Producto</span>
              <AiOutlineArrowDown className="icon-sm" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <InputCustom
                  placeholder={'EAN'}
                  onChange={(event) => onChangeFilter(event, 'ean')}
                />
                <InputCustom
                  placeholder={'titulo'}
                  onChange={(event) => onChangeFilter(event, 'titulo')}
                />
                <InputCustom
                  onChange={(event) => onChangeFilter(event, 'autor')}
                  placeholder={'Autor'}
                />
                <InputCustom
                  onChange={(event) => onChangeFilter(event, 'editorial')}
                  placeholder={'Editorial'}
                />
                <InputCustom
                  onChange={(event) => onChangeFilter(event, 'materia_cdu')}
                  placeholder={'Materias/CDU'}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>País de publicación</span>
              <AiOutlineArrowDown className="icon-sm" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{PubCountryRadios}</Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Idioma</span>
              <AiOutlineArrowDown className="icon-sm" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>{lanProductRadios}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {/* <div className='d-flex justify-content-center flex-fill'>
          <Button variant='secondary' className='flex-fill mt-3' onClick={() => search()}>Buscar</Button>
        </div> */}
      </Form>
    </div>
  )
}

export default FilterProductBooks
